import React, { useState, useRef, useEffect } from "react";
import { useRoutes, useLocation, defer } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import {
  AuthGet,
  AuthPost,
  AuthPut,
  AuthFile,
} from "../../common_var/httpService";
import { useStateValue } from "../../Context/StateProvider";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Toast from "../Toaster/Toast";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import pauseicon from "../../assets/images/pause-icon.svg";
import tickicon from "../../assets/images/tick-icon.svg";
import proceduredate from "../../assets/images/proceduredate.svg";
import { amount, addPrefix, addSuffix } from "../Regex/Regex";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { zerocheck } from "../Commonservice";
import closeIcon from "../../assets/images/close-icon.svg";

const CustomModal = ({
  btnName,
  data,
  fun,
  portal,
  dynamicClass,
  deferdata,
  userdata,
  deferfunction,
  borrowerfun,
  setdeferdata,
  getUserDetails,
}) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const commentsSubject = useRef("");
  const commentsComment = useRef("");
  const [{ currency, socket }, dispatch] = useStateValue();
  const [doctype, setDoctype] = useState("");
  const [file, setFile] = useState("");
  const [dueDate, setDueDate] = useState("");
  const procedureDate = useRef("");
  const [initialState,authState] = useStateValue();
  console.log('initialState::: ', initialState);

  const [currentDate, setCurrentDate] = useState("");
  const [maxDate, setMaxDate] = useState("");
  // let currentDate;
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [amend, setAmends] = useState(0);
  const [pending, setPending] = useState(0);
  const [waiveVal, setWaiveVal] = useState("");
  const [senddata, setSenddata] = useState({});
  const [erroramend, setErroramend] = useState(false);
  const [borrowerActive, setBorrowerActive] = useState(true);
  const [coborrowerAcive, setCoborrowerActive] = useState(false);
  const queryParams = useLocation();
  console.log("amend", amend);
  let params = queryParams.pathname.split("/");
  const loanId = params[params.length - 1];
  const loanStage = params[params.length - 2];
  // console.info('sd-------------------------------->',deferdata)

  useEffect(() => {
    getCurrentDate();
  }, [btnName === "Approve"]);
  useEffect(() => {
    // getlist();
    setPage(Math.ceil(defer?.paymentScheduleDetails?.length / 10));
    setCurrentPage(1);
  }, []);
  useEffect(() => {
    console.log("sd-------------------------------->", deferdata);
  }, []);

  const pagination = paginationFactory({
    page: page,
    sizePerPage: 10,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
      setCurrentPage(page);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
    },
  });

  const getCurrentDate = () => {
    let date = new Date();
    date = date.toISOString();
    date = date.split("T")[0];
    console.log("Current Date: ", date);
    setCurrentDate(date);
    let limited = new Date();
    limited.setFullYear(limited.getFullYear() + 10);
    limited = limited.toISOString();
    limited = limited.split("T")[0];
    console.log("Max Date: ", limited);
    setMaxDate(limited);
  };
  // getCurrentDate();

  let getprocedureDate = JSON.parse(sessionStorage.getItem("procedure_date"));
  console.log("Get prodedure: ", getprocedureDate);
  const [updateProcedureDate, setUpdateProcedureDate] = useState({
    procedure_startdate: getprocedureDate?.procedure_startdate,
    payment_duedate: getprocedureDate?.payment_duedate,
    loanamount: getprocedureDate?.loanamount,
  });

  const handleShow = async () => {
    if (btnName === "Approve") {
      const res = await AuthGet(
        `plaid/check-user-bankaccount/${loanId}`,
        "admin"
      );
      if (!res.data.length) {
        toast.error("Kindly Send Request To The Borrower To Connect Bank");
        return;
      }
    }

    if (fun) {
      fun();
    }
    if (borrowerfun) {
      borrowerfun();
    }
    let a = false;
    if (deferfunction) {
      await AuthGet(
        `loanstage/defer-and-current/${loanId}/${loanStage}`,
        "admin"
      )
        .then((res) => {
          //  console.error(res.data.paymentScheduleDetails[0])
          console.log("Loan details data: ", res);
          if (res["statusCode"] != 200) {
            a = true;
            return;
          } else {
            setdeferdata({
              paymentScheduleDetails: res?.data?.data?.paymentScheduleDetails,
              curentscheduleDate: res?.data?.data?.paymentScheduleDetails[0],
              defer: res?.data?.data?.defer_payment,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (a == true) {
      toast.error("Lms internal error");
      return handleClose();
    }

    setShow(true);
    getprocedureDate = JSON.parse(sessionStorage.getItem("procedure_date"));
    console.log("Show: ", getprocedureDate?.procedure_startdate);
    setUpdateProcedureDate({
      procedure_startdate: getprocedureDate?.procedure_startdate,
      payment_duedate: getprocedureDate?.payment_duedate,
      loanamount: getprocedureDate?.loanamount,
    });

    if (userdata?.[0]?.Amount) {
      let amount = userdata[0]?.Amount.toFixed(2);
      setAmends(amount);
    }
  };
  const handleClose = () => {
    setShow(false);
    console.log("closed", deferdata?.curentscheduleDate.amount);
    setDueDate("");
    setPending(0);
    setWaiveVal("");
    setSenddata({});
  };

  let loan_id = sessionStorage.getItem("loan_id");
  if (portal == "borrower") {
    loan_id = sessionStorage.getItem("LoanId");
  }

  const formik = useFormik({
    initialValues: {
      subject: "",
      comments: "",
    },
    validationSchema: Yup.object({
      subject: Yup.string().required("*Subject is Required"),
      comments: Yup.string().required("*Comment is Required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      let user_id = sessionStorage.getItem("UserId");

      let commentsData = {
        ...values,
        loan_id: data,
        user_id,
      };
      console.log("data: ", commentsData);
      await AuthPost(`opportunities/make-comment`, commentsData, "admin")
        .then((res) => {
          console.log("Comments", res);
          if (res.statusCode === 200) {
            toast.success("Query Added Successfully");
            handleClose();
            formik.resetForm();
            fun();
          }
        })
        .catch((err) => {
          console.log("err--->", err);
          toast.error(err.message);
        });
    },
  });

  // const handleComment = async () => {

  // }

  const dropDoctype = (e) => {
    console.log("Doc type: ", e.target.value);
    setDoctype(e.target.value);
  };

  const dropDueDate = (e) => {
    console.log("Due date type: ", e.target.value);
    setDueDate(e.target.value);
  };

  const handleFileChange = (e) => {
    console.log("Uploaded file: ", e.target.files[0]);
    setFile(e.target.files[0]);
  };

  const handleUploadDocument = async () => {
    console.log("Document type: ", doctype);
    console.log("File: ", file);
    let user_id = sessionStorage.getItem("UserId");
    const formData = new FormData();
    formData.append("type", doctype);
    formData.append("loan_id", data);
    formData.append("files", file);
    formData.append("user_id", user_id);
    console.log(formData, "Formdata");

    await AuthFile(`uploadfiles/uploads/`, formData, "admin")
      .then((res) => {
        // getDocs(id);
        if (res.statusCode === 200) {
          toast.success("File Uploaded");

          setFile("");
          setDoctype("");
          handleClose();
          fun();
        }
        console.log("res---->", res);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
      });
  };

  const handleApproveApplication = async () => {
    console.log("due date: ", dueDate);
    // console.log("Procedure date: ", procedureDate.current.value);

    let dateData = {
      payment_duedate: dueDate,
      // procedure_startdate: procedureDate.current.value
    };

    if (dateData.payment_duedate === "") {
      toast.error("Select Date");
    } else {
      await AuthPost(`loanstage/approve-loan/${loan_id}`, dateData, "admin")
        .then((res) => {
          if (res["statusCode"] == 200) {
            sendNotification("ApprovalOfLoanRequest", "");
            console.log("Res Data: ", res);
            navigate(`/admin/approved`);
            toast.success(`Application successfully moved to Deals`);
          } else if (res.statusCode === 400) {
            handleClose();
            toast.error(res.message[0]);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.message);
        });
    }
  };

  const handleUpdateDate = async (e) => {
    setUpdateProcedureDate({
      procedure_startdate: e.target.value,
      payment_duedate: getprocedureDate?.payment_duedate,
      loanamount: getprocedureDate?.loanamount,
    });
  };

  const submitUpdateDate = async () => {
    console.log("Update date: ", updateProcedureDate);
    let dateData = {
      procedure_startdate: updateProcedureDate?.procedure_startdate,
    };
    console.log("Date data: ", dateData);
    await AuthPost(`loanstage/update-date/${loan_id}`, dateData, "admin")
      .then((res) => {
        if (res["statusCode"] == 200) {
          console.log("Res Data: ", res);
          console.log(
            " updateProcedureDate?.procedure_startdate",
            updateProcedureDate?.procedure_startdate
          );
          sendNotification("ProcedureDateChange", {
            from_date: res.data[0].from_date,
            to_date: res.data[0].to_date,
          });
          // updateProcedureDate?.procedure_startdate = res.data[0].to_date
          sessionStorage.setItem(
            "procedure_date",
            JSON.stringify({
              procedure_startdate: res.data[0].to_date,
              payment_duedate: updateProcedureDate?.payment_duedate,
              loanamount: updateProcedureDate?.loanamount,
            })
          );
          handleClose();
          toast.success(`Procedure date updated`);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
      });
  };

  const sendNotification = async (stage, body) => {
    let bodyData = {
      ...body,
      stage: stage,
      loan_id: loan_id,
      user_id: "",
    };
    console.log("bodyData", bodyData);
    await AuthPost(`socket/saveNotification`, bodyData, "admin")
      .then((res) => {
        if (res["statusCode"] == 200) {
          if (res["data"].length > 0) {
            for (var user of res["data"]) {
              // console.log('sssssssss.........................', {
              //     receiver_user_id: user.user_id,
              //     notification: { id: user.user_id, 'message': user.content },
              // })
              socket?.emit("sendNotification", {
                receiver_user_id: user.user_id,
                notification: { id: user.user_id, message: user.content },
              });
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
      });
  };

  const handleConfirmProcedure = async () => {
    await AuthPost(`loanstage/add-loan-to-funded/${loan_id}`, {}, "admin")
      .then((res) => {
        if (res["statusCode"] == 200) {
          sendNotification("FundDisburse", "");
          console.log("Res Data: ", res);
          handleClose();
          navigate(`/admin/fundingcontract`);
          toast.success(`Application successfully moved to Fund Disbursed`);
        } else if (res.statusCode === 400) {
          handleClose();
          toast.error(res.message[0]);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
      });
  };

  const formikCreateCounterOffer = useFormik({
    initialValues: {
      financialAmount: "",
      originationFee: "",
      interestRate: "",
      duration: "",
    },
    validationSchema: Yup.object({
      financialAmount: Yup.string().required("*Financial Amount is required"),
      originationFee: Yup.string().required("*Origination Fee is required"),
      interestRate: Yup.string().required("*Interest Rate is required"),
      duration: Yup.string().required("*Duration is required"),
    }),
    onSubmit: async (values) => {
      values.loan_id = data;
      console.log("Create counter offer values: ", values);
      values.financialAmount = parseInt(values.financialAmount);
      values.originationFee = parseInt(values.originationFee);
      values.interestRate = parseInt(values.interestRate);
      values.duration = parseInt(values.duration);
      console.log("Create counter offer values: ", values);

      await AuthPost(`offers/originate-offer/`, values, "admin")
        .then((res) => {
          if (res.statusCode == 200) {
            toast.success("Offer created successfully");
            handleClose();
            formikCreateCounterOffer.resetForm();
            fun();
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.message);
        });

      // values.financialAmount = "";
      // values.originationFee = "";
      // values.interestRate = "";
      // values.duration = "";
    },
  });

  const handleMakePayment = async () => {
    if (borrowerfun) {
      makepayment("payment", "borrower");
    } else {
      makepayment("loanstage", "admin");
    }
  };
  let makepayment = async (service, key) => {
    console.warn("kieuhr", service, key);
    await AuthPost(`${service}/disburse-fund`, { loan_id }, key)
      .then((res) => {
        if (res.statusCode == 200) {
          toast.success("Payment successful");
          handleClose();
          if (fun) {
            fun();
          }
          if (borrowerfun) {
            borrowerfun();
          }
        } else {
          handleClose();
          toast.error(res["message"]);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
      });
  };

  const handledefer = async () => {
    await AuthPost(
      `loanstage/updatedefer/${loan_id}/fundingcontract`,
      {},
      "admin"
    )
      .then((res) => {
        if (res.statusCode == 200) {
          toast.success("successful");
          handleClose();
          if (fun) {
            fun();
          }
        } else {
          handleClose();
          toast.error(res["message"]);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
      });
  };
  console.log("FILE", file, doctype);

  const column = [
    {
      dataField: "",
      text: "S.NO",
      formatter: (e, column, columnIndex, row, rowIndex) => {
        let total = currentPage * 5;
        total = total - 5;
        let slNo = total + columnIndex + 1;
        console.log("Total: ", total);
        return slNo;
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "unpaidprincipal",
      text: "Unpaid Principal Balance",
      formatter: (cell) => {
        return currency + cell;
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "principal",
      text: "Principal",
      formatter: (cell) => {
        return currency + cell;
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "interest",
      text: "Interest",
      formatter: (cell) => {
        return currency + cell;
      },
      style: {
        overflow: "hidden",
      },
    },
    // {
    //     dataField: "fees", text: 'Fees',
    //     formatter: (cell) => {
    //         if (cell === ".00") {
    //             return "$ 0.00";
    //         }
    //         return currency + cell;
    //     },
    //     style: {
    //         overflow: 'hidden'
    //     }
    // },
    {
      dataField: "pastDue",
      text: "Past due",

      formatter: (cell) => {
        if (cell == ".00") {
          return `${currency}0` + cell;
        } else if (!cell) {
          return "0";
        } else {
          return currency + cell;
        }
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "amount",
      text: "Amount",
      formatter: (cell) => {
        return currency + cell;
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "scheduledate",
      text: "Schedule Date",
      formatter: (cell) => {
        return cell?.split("T")[0];
      },
      style: {
        overflow: "hidden",
      },
    },
  ];

  const defercolumn = [
    {
      dataField: "",
      text: "S.NO",
      formatter: (e, column, columnIndex, row, rowIndex) => {
        let total = currentPage * 5;
        total = total - 5;
        let slNo = total + columnIndex + 1;
        console.log("Total: ", total);
        return slNo;
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "unpaidPrincipal",
      text: "Unpaid Principal Balance",
      formatter: (cell) => {
        if (!cell?.toString()?.includes(".")) {
          return currency + cell?.toFixed(2);
        } else {
          return currency + cell;
        }
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "principal",
      text: "Principal",
      formatter: (cell) => {
        return currency + cell;
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "interest",
      text: "Interest",
      formatter: (cell) => {
        return currency + cell;
      },
      style: {
        overflow: "hidden",
      },
    },
    // {
    //     dataField: "fees", text: 'Fees',
    //     formatter: (cell) => {
    //         if (cell === ".00") {
    //             return "$ 0.00";
    //         }
    //         return currency + cell;
    //     },
    //     style: {
    //         overflow: 'hidden'
    //     }
    // },
    {
      dataField: "pastDue",
      text: "Past due",

      formatter: (cell) => {
        return zerocheck(cell, currency);
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "amount",
      text: "Amount",
      formatter: (cell) => {
        return currency + cell;
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "scheduleDate",
      text: "Schedule Date",
      formatter: (cell) => {
        let date = cell?.split("T")[0];
        let datesplit = date.split("-");
        if (datesplit[0].length == 4) {
          date = `${datesplit[1]}-${datesplit[2]}-${datesplit[0]}`;
          return date;
        } else {
          return date;
        }
      },
      style: {
        overflow: "hidden",
        whiteSpace: "nowrap",
      },
    },
  ];

  const columns = [
    {
      dataField: "",
      text: "S.NO",
      formatter: (e, column, columnIndex, row, rowIndex) => {
        let total = currentPage * 5;
        total = total - 5;
        let slNo = total + columnIndex + 1;
        console.log("Total: ", total);
        return slNo;
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "unpaidprincipal",
      text: "Unpaid Principal Balance",
      formatter: (cell) => {
        return currency + cell;
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "principal",
      text: "Principal",
      formatter: (cell) => {
        return currency + cell;
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "interest",
      text: "Interest",
      formatter: (cell) => {
        return currency + cell;
      },
      style: {
        overflow: "hidden",
      },
    },
    // {
    //     dataField: "fees", text: 'Fees',
    //     formatter: (cell) => {
    //         if (cell === ".00") {
    //             return "$ 0.00";
    //         }
    //         return currency + cell;
    //     },
    //     style: {
    //         overflow: 'hidden'
    //     }
    // },
    {
      dataField: "pastDue",
      text: "Past due",

      formatter: (cell) => {
        return zerocheck(cell, currency);
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "amount",
      text: "Amount",
      formatter: (cell) => {
        return currency + cell;
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "scheduledate",
      text: "Schedule Date",
      formatter: (cell) => {
        let date = cell?.split("T")[0];
        let datesplit = date.split("-");
        if (datesplit[0].length == 4) {
          date = `${datesplit[1]}-${datesplit[2]}-${datesplit[0]}`;
          return date;
        } else {
          return date;
        }
      },
      style: {
        overflow: "hidden",
        whiteSpace: "nowrap",
      },
    },
  ];

  let settingamend = (e) => {
    setAmends(e);
    let b = Number.parseFloat(e).toFixed(2);
    let x = b;
    let z = userdata[0]?.Amount.toFixed(2);
    let amendVal = z - x;
    let c = Number.parseFloat(amendVal).toFixed(2);
    console.log("nh", c);
    if (amendVal < 0) {
      setPending(0);
    } else {
      setPending(c);
    }
  };

  const handleChange = (e) => {
    let max = userdata[0]?.Amount.toFixed(2);
    const value = Math.max(0, Math.min(max, Number(e.target.value)));
    if (value > 1) {
      setAmends(value);
    } else {
      setAmends(e.target.value);
    }
  };

  const handleapend = async () => {
    console.log(pending, amend, userdata[0].Amount);
    if (pending && amend && userdata[0].Amount) {
      if (pending > 0) {
        if (amend == "0") {
          return toast.error("To be paid amount must be more than 0");
        }
        let pend = pending.split(",").join("");
        console.log("------------->/", Number(pend));
        let amount = Number.parseFloat(userdata[0].Amount).toFixed(2);
        let senddata = {
          totalamount: Number(amount),
          tobepaid: typeof amend == "number" ? amend : Number(amend),
          pendingamount: Number(pend),
        };

        await AuthPost(`loanstage/amendpayment/${loan_id}`, senddata, "admin")
          .then((res) => {
            if (res.statusCode == 200) {
              toast.success("successful");
              handleClose();
              if (fun) {
                fun();
              }
            } else {
              handleClose();
              toast.error(res["message"]);
            }
          })
          .catch((err) => {
            console.log(err);
            toast.error(err.message);
          });
      } else {
        toast.error("Pending amount is Required");
      }
    } else {
      toast.error("Pending amount is Required");
    }
  };

  const styles = {
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-evenly",
    marginTop: "20px",
  };
  let waveoption = (e) => {
    setWaiveVal(e.target.value);
    console.log(
      "----------------------------------------------------0>",
      e.target.value
    );
  };

  let handleWaive = () => {
    if (waiveVal) {
      if (waiveVal == "PartialWaive") {
        if (pending && amend && userdata[0].Amount) {
          if (pending > 0) {
            if (amend == "0") {
              return toast.error("to be paid amount must more than 0");
            }
            console.log(pending, amend, userdata[0].Amount);
            let pend = pending.split(",").join("");
            console.log(
              "------------------------------------------->/",
              Number(pend)
            );
            let amount = Number.parseFloat(userdata[0].Amount).toFixed(2);

            setSenddata({
              typeofwaive: waiveVal,
              totalamount: Number(amount),
              tobepaid: amend,
              pendingamount: Number(pend),
            });
            let senddatas = {
              typeofwaive: waiveVal,
              totalamount: Number(amount),
              tobepaid: typeof amend == "number" ? amend : Number(amend),
              pendingamount: Number(pend),
            };
            waivePost(senddatas);
          } else {
            toast.error("Waive amount is Required");
          }
        } else {
          toast.error("Waive amount is Required");
        }
      } else {
        let senddatas = {
          typeofwaive: waiveVal,
        };
        waivePost(senddatas);
      }
    } else {
      toast.error("Select Waive type");
    }
  };
  console.log("-----------------><><><>", senddata);

  let waivePost = async (e) => {
    console.log("yes-------------=======>", e);
    await AuthPost(`loanstage/waivepayment/${loan_id}`, e, "admin")
      .then((res) => {
        if (res.statusCode == 200) {
          toast.success("successful");
          handleClose();
          if (fun) {
            fun();
          }
        } else {
          handleClose();
          toast.error(res["message"]);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
      });
  };
  const waivestyle = {
    display: "flex",
  };

  const handleCheckboxChange = (checkboxNumber) => {
    setBorrowerActive(checkboxNumber === 1);
    setCoborrowerActive(checkboxNumber === 2);
  };
  console.log("los", borrowerActive, coborrowerAcive, data);

  const handleSwapBorrower = async (e) => {
    e.preventDefault();
    if (borrowerActive) {
      return handleClose();
    }
    console.log("call connected");

    await AuthPost(`loan/swap-Borrower/${loan_id}`, {}, "admin")
    .then((res) => {
      if(res.statusCode === 200) {
        toast.success(`${res.message}`);
        handleClose();
        getUserDetails();

      }else{
        toast.error(res.message);
        handleClose();
      }
    })
    .catch((err) => {
      console.log("Put error: ", err);
      // toast.error(err.message);
    })
  };
  return (
    <>
      <Toast />
      {/* <button className="btn btn-primary" onClick={handleShow}>
                {btnName}
            </button> */}
      {btnName === "Approve" ? (
        <button
          className="btn approveButton buttontooltip"
          onClick={handleShow}
        >
          {/* {btnName} */}
          <img src={tickicon} />
          <div className="top">
            <p>{btnName}</p>
            <i></i>
          </div>
        </button>
      ) : btnName === "Update Procedure Date" ? (
        <button
          className="btn proceduredate buttontooltip"
          onClick={handleShow}
        >
          {/* {btnName} */}
          <img src={proceduredate} />
          <div className="top">
            <p>{btnName}</p>
            <i></i>
          </div>
        </button>
      ) : btnName === "Confirm Procedure" ? (
        <button
          className="btn approveButton buttontooltip"
          onClick={handleShow}
        >
          {/* {btnName} */}
          <img src={tickicon} />
          <div className="top">
            <p>Approve</p>
            <i></i>
          </div>
        </button>
      ) : (
        <button
          className={
            dynamicClass == "addbutton" ? "addbutton" : "btn btn-primary"
          }
          onClick={handleShow}
        >
          {" "}
          {btnName}
        </button>
      )}

      {btnName === "Add Any Query" ? (
        <Modal
          show={show}
          onHide={() => {
            handleClose();
            formik.resetForm();
          }}
        >
          <form onSubmit={formik.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Add New Query</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <label htmlFor="subject">Subject</label>
              <input
                type="text"
                placeholder="Subject"
                id="subject"
                name="subject"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.subject}
                className="form-control inputField mb10px"
              />
              {formik.touched.subject && formik.errors.subject ? (
                <p className="requiredText">{formik.errors.subject}</p>
              ) : null}{" "}
              <br />
              <label htmlFor="comments">Comment</label>
              <textarea
                cols="30"
                rows="10"
                placeholder="Comment"
                className="form-control inputField mb10px"
                id="comments"
                name="comments"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.comments}
              />
              {formik.touched.comments && formik.errors.comments ? (
                <p className="requiredText">{formik.errors.comments}</p>
              ) : null}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                type="button"
                onClick={() => {
                  handleClose();
                  formik.resetForm();
                }}
              >
                Cancel
              </Button>
              <Button variant="primary" type="submit">
                Add Query
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      ) : btnName === "Upload Documents" ? (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Upload Documents</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <select
              name="documentType"
              value={doctype}
              onChange={dropDoctype}
              multiple={false}
              className="form-control inputField mb10px"
            >
              <option value="">Select Document Type</option>
              <option value="Government Issued ID Front">
                Government Issued ID Front
              </option>
              <option value="Government Issued ID Back">
                Government Issued ID Back
              </option>
              <option value="Paystub 1">Paystub 1</option>
              <option value="Paystub 2">Paystub 2</option>
              <option value="Bank Statement">Bank Statement</option>
              <option value="Income Document">Income Document</option>
              <option value="Driving License">Driving License</option>
              <option value="Other">Other</option>
            </select>
            {file != "" && (
              <div className="form-control inputField mb10px">{file.name}</div>
            )}
            <div className="form-control inputField mb10px">
              <label htmlFor="fileUpload">
                Upload file
                <input
                  onChange={handleFileChange}
                  type="file"
                  accept="image/png, application/pdf"
                  id="fileUpload"
                  hidden
                />
              </label>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                handleClose();
                setFile("");
                setDoctype("");
              }}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={handleUploadDocument}>
              Upload
            </Button>
          </Modal.Footer>
        </Modal>
      ) : btnName === "Generate Deal" ? (
        <Modal
          show={show}
          onHide={() => {
            handleClose();
            formikCreateCounterOffer.resetForm();
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Generate Deal</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={formikCreateCounterOffer.handleSubmit}>
              <div>
                <div>
                  <label htmlFor="financialNumber">Financial Amount</label>
                  <input
                    type="text"
                    id="financialNumber"
                    name="financialAmount"
                    class="form-control inputField mb10px"
                    // onChange={(e) => {
                    //     formikCreateCounterOffer.setFieldValue(
                    //         "financialAmount",
                    //         amount(e.target.value, '$ ')
                    //     );
                    // }}
                    onChange={formikCreateCounterOffer.handleChange}
                    value={formikCreateCounterOffer.values.financialAmount}
                    onBlur={formikCreateCounterOffer.handleBlur}
                    placeholder="Enter Financial Amount"
                  />

                  {formikCreateCounterOffer.touched.financialAmount &&
                  formikCreateCounterOffer.errors.financialAmount ? (
                    <p className="error_text">
                      {formikCreateCounterOffer.errors.financialAmount}
                    </p>
                  ) : null}
                </div>
                <div>
                  <label htmlFor="interestRate">Interest Rate</label>
                  <input
                    type="text"
                    id="interestRate"
                    name="interestRate"
                    class="form-control inputField mb10px"
                    value={formikCreateCounterOffer.values.interestRate}
                    onChange={formikCreateCounterOffer.handleChange}
                    // onChange={(e) => {
                    //     formikCreateCounterOffer.setFieldValue(
                    //         "interestRate",
                    //         addSuffix(e.target.value, ' %')
                    //     );
                    // }}
                    onBlur={formikCreateCounterOffer.handleBlur}
                    placeholder="Enter Interest Rate"
                  />

                  {formikCreateCounterOffer.touched.interestRate &&
                  formikCreateCounterOffer.errors.interestRate ? (
                    <p className="error_text">
                      {formikCreateCounterOffer.errors.interestRate}
                    </p>
                  ) : null}
                </div>
                <div>
                  <label htmlFor="duration">Term</label>
                  <select
                    name="duration"
                    id="duration"
                    className="form-control inputField mb10px"
                    value={formikCreateCounterOffer.values.duration}
                    onChange={formikCreateCounterOffer.handleChange}
                    onBlur={formikCreateCounterOffer.handleBlur}
                  >
                    <option selected disabled value="">
                      Duration
                    </option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="18">18</option>
                    <option value="24">24</option>
                  </select>

                  {formikCreateCounterOffer.touched.duration &&
                  formikCreateCounterOffer.errors.duration ? (
                    <p className="error_text">
                      {formikCreateCounterOffer.errors.duration}
                    </p>
                  ) : null}
                </div>
                <div>
                  <label htmlFor="originationFee">Origination Fee</label>
                  <input
                    type="text"
                    id="originationFee"
                    name="originationFee"
                    class="form-control inputField mb10px"
                    onChange={formikCreateCounterOffer.handleChange}
                    value={formikCreateCounterOffer.values.originationFee}
                    // onChange={(e) => {
                    //     formikCreateCounterOffer.setFieldValue(
                    //         "originationFee",
                    //         amount(e.target.value, '$ ')
                    //     );
                    // }}
                    onBlur={formikCreateCounterOffer.handleBlur}
                    placeholder="Enter Origination Fee"
                  />

                  {formikCreateCounterOffer.touched.originationFee &&
                  formikCreateCounterOffer.errors.originationFee ? (
                    <p className="error_text">
                      {formikCreateCounterOffer.errors.originationFee}
                    </p>
                  ) : null}
                </div>
              </div>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => {
                    handleClose();
                    formikCreateCounterOffer.resetForm();
                  }}
                >
                  Cancel
                </Button>
                <Button type="submit">Save</Button>
              </Modal.Footer>
            </form>
          </Modal.Body>
        </Modal>
      ) : btnName === "Approve" ? (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Financing Approval Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <div>
                                            <label htmlFor="date">Procedure start date</label> <br />
                                            <input type="date" className="form-control inputField mb10px" min={currentDate} ref={procedureDate} max={maxDate} />
                                        </div> */}
            <label htmlFor="">Payment Due date</label>
            <select
              name="documentType"
              onChange={dropDueDate}
              className="form-control inputField mb10px"
            >
              <option value="">Select Payment Due date</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
              <option value="13">13</option>
              <option value="14">14</option>
              <option value="15">15</option>
              <option value="16">16</option>
              <option value="17">17</option>
              <option value="18">18</option>
              <option value="19">19</option>
              <option value="20">20</option>
              <option value="21">21</option>
              <option value="22">22</option>
              <option value="23">23</option>
              <option value="24">24</option>
              <option value="25">25</option>
              <option value="26">26</option>
              <option value="27">27</option>
              <option value="28">28</option>
              <option value="29">29</option>
              <option value="30">30</option>
              <option value="30">31</option>
            </select>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleApproveApplication}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      ) : btnName === "Update Procedure Date" ? (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Financing Approval Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <label htmlFor="date">Procedure start date</label> <br />
              <input
                type="date"
                className="form-control inputField mb10px"
                min={currentDate}
                onChange={(e) => handleUpdateDate(e)}
                defaultValue={`${
                  updateProcedureDate?.procedure_startdate?.split("-")[2]
                }-${updateProcedureDate?.procedure_startdate?.split("-")[0]}-${
                  updateProcedureDate?.procedure_startdate?.split("-")[1]
                }`}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={submitUpdateDate}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      ) : btnName === "Confirm Procedure" ? (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              Are you sure you want to approve this application?
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              {/* <div>
                Procedure start date :
                <span> {updateProcedureDate?.procedure_startdate}</span>
              </div>{" "}
              <br /> */}
              <div>
                {/* Amount :<span> {currency} {updateProcedureDate?.loanamount}</span>
                 */}
                 Amount : {initialState?.currency}{
                  updateProcedureDate?.loanamount === ".00"?"0.00":updateProcedureDate?.loanamount
                 }


              </div>{" "}
              <br />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleConfirmProcedure}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      ) : btnName === "Select a Borrower" ? (
        <Modal show={show}>
          <Modal.Header>
            <Modal.Title>Select Primary Borrower</Modal.Title>
            <img src={closeIcon} alt="close" onClick={handleClose} />
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div class="col-md-12 ">
              <div className="shadow"
                
                style={{
                  display: "block",
                  marginBottom: "20px",
                  borderRadius:"10px",
                  padding:"10px",
                  border:"1px solid grey",

                 
                }}
              >
             <div className="displayFlex  spaceBetween AlignItemCenter">
             <label style={{fontWeight:"bold",fontSize:"18px",paddingBottom:"10px",}}>
                  Borrower{" "}
                  
                  {/* <input
                    type="checkbox"
                    onClick={() => {
                      handleCheckboxChange(1);
                    }}
                    checked={borrowerActive}
                  ></input> */}
                </label>
                <div>
                    <label className="switch">
                      <input
                        className="switch-input"
                        type="checkbox"
                        onClick={() => {
                          handleCheckboxChange(1);
                        }}
                        checked={borrowerActive}
                      />
                      <span className="switch-label toggle_bgred"></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>
             </div>
                <table class="">
                  <tbody>
                    <tr>
                      <td>Name: </td>
                      <td>
                        {" "}
                        {data?.data?.firstname + " " + data?.data?.lastname ??
                          "--"}{" "}
                      </td>
                    </tr>
                    <tr>
                      <td>Email: </td>
                      <td>{data?.data?.email ?? "--"}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              </div>
              <div className="col-md-12">
                <div
                  class="shadow"
                  style={{ display: "block",
                  marginBottom: "20px",
                  borderRadius:"10px",
                  padding:"10px",
                  }}
                >
                <div className="displayFlex  spaceBetween AlignItemCenter">
                <label style={{fontWeight:"bold",fontSize:"18px",paddingBottom:"10px",}}>
                    Co-borrower
                    
                    {/* <input
                      type="checkbox"
                      onClick={() => {
                        handleCheckboxChange(2);
                      }}
                      checked={coborrowerAcive}
                    ></input> */}
                  </label>
                  <div>
                    <label className="switch">
                      <input
                        className="switch-input"
                        type="checkbox"
                        onClick={() => {
                            handleCheckboxChange(2);
                          }}
                          checked={coborrowerAcive}
                      />
                      <span className="switch-label toggle_bgred"></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>
                </div>
                  <table class="">
                    <tbody>
                      <tr>
                        <td>Name: </td>
                        <td>
                          {" "}
                          {data?.coborrower?.firstname +
                            " " +
                            data?.coborrower?.lastname ?? "--"}{" "}
                        </td>
                      </tr>
                      <tr>
                        <td>Email: </td>
                        <td> {data?.coborrower?.email ?? "--"}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={(e) => {
                handleSwapBorrower(e);
              }}
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      ) : btnName === "Make Payment" ? (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="makePayment">
              <b>Are you sure you want to make this payment?</b>
            </div>
            <div>
              <BootstrapTable
                bootstrap4
                keyField="id"
                data={[userdata?.[0]]}
                columns={columns}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleMakePayment}>
              Yes
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              No
            </Button>
          </Modal.Footer>
        </Modal>
      ) : btnName === "Amend payment" ? (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Amend Payment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="makePayment">
              <b>Current payment</b>
              <div>
                <BootstrapTable
                  bootstrap4
                  keyField="id"
                  data={[userdata[0]]}
                  columns={columns}
                />
              </div>
              <div style={styles}>
                <span>
                  {" "}
                  <span>To be paid</span> <br />
                  <input
                    type="number"
                    placeholder="           *Required"
                    max={userdata[0]?.Amount.toFixed(2)}
                    value={amend}
                    onChange={(e) => {
                      settingamend(e.target.value);
                      handleChange(e);
                    }}
                  ></input>
                </span>
                <br />

                <span>
                  {" "}
                  <span>Pending</span>
                  <br />
                  <input
                    type="number"
                    placeholder="           *Required"
                    readOnly
                    value={pending}
                  ></input>
                </span>
                {erroramend ? (
                  <span>paying cost should not be more than amount</span>
                ) : (
                  ""
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleapend}>
              Yes
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              No
            </Button>
          </Modal.Footer>
        </Modal>
      ) : btnName === "Waive payment" ? (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="makePayment">
              <b>Current payment</b>
              <div>
                <BootstrapTable
                  bootstrap4
                  keyField="id"
                  data={[userdata[0]]}
                  columns={columns}
                />
              </div>
              <div>
                <div onChange={waveoption}>
                  <span>
                    <input
                      type="radio"
                      value="Waive"
                      name="waive"
                      checked={waiveVal === "Waive"}
                    />
                    Full Waive{" "}
                  </span>
                  <span>
                    {" "}
                    <input
                      type="radio"
                      value="PartialWaive"
                      name="waive"
                      checked={waiveVal === "PartialWaive"}
                    />{" "}
                    Partial Waive
                  </span>
                </div>
              </div>

              {waiveVal == "PartialWaive" ? (
                <div style={styles}>
                  <span>
                    {" "}
                    <span>To be paid</span> <br />
                    <input
                      type="number"
                      placeholder="           *Required"
                      max={userdata[0]?.Amount.toFixed(2)}
                      value={amend}
                      onChange={(e) => {
                        settingamend(e.target.value);
                        handleChange(e);
                      }}
                    ></input>
                  </span>
                  <br />

                  <span>
                    {" "}
                    <span>Waive amount</span>
                    <br />
                    <input
                      type="number"
                      placeholder="           *Required"
                      readOnly
                      value={pending}
                    ></input>
                  </span>
                  {erroramend ? (
                    <span>paying cost should not be more than amount</span>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleWaive}>
              Yes
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              No
            </Button>
          </Modal.Footer>
        </Modal>
      ) : btnName === "Defer payment" ? (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="makePayment">
              {deferdata ? (
                <div>
                  <div className="frameshadow mb20px">
                    <h5>Current Repayment Calendar</h5>
                    <div>
                      <BootstrapTable
                        bootstrap4
                        keyField="id"
                        data={deferdata?.paymentScheduleDetails}
                        columns={columns}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
            <div className="makePayment">
              {deferdata ? (
                <div>
                  <div className="frameshadow mb20px">
                    <h5>Defer payment Calendar</h5>
                    <div>
                      <BootstrapTable
                        bootstrap4
                        keyField="id"
                        data={deferdata?.defer}
                        columns={defercolumn}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            {btnName === "Defer payment" ? (
              <Button variant="primary" onClick={handledefer}>
                Confirm
              </Button>
            ) : (
              <Button variant="primary" onClick={handleMakePayment}>
                Yes
              </Button>
            )}

            {btnName === "Defer payment" ? (
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
            ) : (
              <Button variant="secondary" onClick={handleClose}>
                No
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default CustomModal;
