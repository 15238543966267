import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Outlet, Navigate, useNavigate, useLocation } from "react-router-dom";
import { useStateValue } from "../../Context/StateProvider";
import Toast from "../Toaster/Toast";

const Guard = ({ data }) => {
  let key = data.key;
  console.log("data.key::: ", data.key);
  let pages = JSON.parse(sessionStorage.getItem("pages"));
  console.log('pages::: ', pages);
  const [previousRoute, setPreviousRoute] = useState( key ==="borrower" ? null : `${data?.key}/${pages[0]?.name}`);
  console.log('previousRoute::: ', previousRoute);
  
  const queryParams = useLocation();
  let pathUrl = queryParams.pathname.split("/");
  console.log("pathUrl::: ", pathUrl);
  
  let navigate = useNavigate();
  let token;
  if (key == "admin") {
    token = sessionStorage.getItem("admin_token");
  } else if (key == "borrower") {
    token = sessionStorage.getItem("borrower_token");
  }
  const expiry = token ? JSON.parse(atob(token.split(".")[1])).exp : null;
  // const clear = (key) => {
  //   console.log('working')
  //   let url = `/${key}/login`;
  //   sessionStorage.clear();
  //   navigate(url);
  // };
  const checkRoles = () => {
    // debugger
    let IsRoleAvailable = pages?.some((page) => pathUrl?.includes(page?.name));
    console.log('IsRoleAvailable::: ', IsRoleAvailable);
    return IsRoleAvailable;
  };

  useEffect(() => {
    if (checkRoles()) {
      setPreviousRoute(queryParams?.pathname);
    } else {
      <Navigate to={previousRoute} />;
    }
  }, [queryParams]);
  return (
    <>
      <Toast />
      <div>
        {expiry > Math.floor(new Date().getTime() / 1000) &&
        key == "borrower" ? (
          console.log('inside borrower'),
          <Outlet />
        ) : expiry > Math.floor(new Date().getTime() / 1000) &&
          checkRoles() &&
          key == "admin" ? (
            console.log('inside admin with outlet'),
          <Outlet />
        ) : expiry > Math.floor(new Date().getTime() / 1000) &&
          !checkRoles() &&
          key == "admin" ? (
            console.log('inside admin previous'),
          <Navigate to={previousRoute} />
        ) : key == "admin" ? (
          (toast("Session Expired", {
            icon: "⌛",
          }),
          (console.log('inside admin login'),
          <Navigate to="/admin/login" />))
        ) : // clear(key)
        key == "borrower" ? (
          (toast("Session Expired", {
            icon: "⌛",
          }),
          (console.log('inside borrower login'),
          <Navigate to="/borrower/login" />))
        ) : (
          // clear(key)
          ""
        )}
      </div>
    </>
  );
};

export default Guard;
