import React, { useState, useCallback } from "react";
import { useStateValue } from "../../Context/StateProvider";
import dropdownicon from "../../assets/images/dropdown_icon.svg";
import completetick from "../../assets/images/tick_track.svg";
import bankicon from "../../assets/images/bank_icon.svg";
import LoanStageStatus from "./LoanStageStatus";
import searchicon from "../../assets/images/search_icon.svg";
import filtericon from "../../assets/images/filter_ico.svg";
import Dropdown from "react-bootstrap/Dropdown";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { AuthGet, AuthPost, Post } from "../../common_var/httpService";

const LoanStagetable = ({
  column = [],
  row = [],
  totalCount = 0,
  itemsPerPage = 10,
  setItemsPerPage,
  currentPage = 1,
  setCurrentPage,
  searchKey,
  setSearchKey,
  isExport = false,
  isFilterHeader = false,
  filterOptions = [],
  filterDefaultValue = [],
  handleFilterSelection = () => null,
  handleExport = () => null,
  handleDealChange,
  toggle,
  page,
  loanParams,
  handleDropDown,
}) => {
  const [initialState, dispatch, authState] = useStateValue();
  const navigate = useNavigate();
  const [pageNumberLimit] = useState(3);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(3);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
  const [timer, setTimer] = useState(null);
  let pages = [];

  console.log("row::: ", row);

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 3000);
    };
  };

  const handleChange = (value) => {
    console.log("Value::", value);
    setSearchKey(value);
  };

  //debounce
  const optimizedFn = useCallback(debounce(handleChange), []);
  //create pages based on total count
  for (let i = 1; i <= Math.ceil(totalCount / itemsPerPage); i++) {
    pages.push(i);
  }

  const handleNext = () => {
    setCurrentPage((prev) => prev + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrev = () => {
    setCurrentPage((prev) => prev - 1);

    if ((currentPage - 1) % pageNumberLimit === 0) {
      setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  let start = (currentPage - 1) * itemsPerPage + 1;
  let end =
    totalCount < currentPage * itemsPerPage
      ? totalCount
      : currentPage * itemsPerPage;

  const handleClick = (event) => {
    setCurrentPage(Number(event.target.id));
  };

  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <div
          key={number}
          id={number}
          onClick={handleClick}
          className={"blueborder_btn  bg_borderred"}
        >
          {number}
        </div>
      );
    } else {
      return <></>;
    }
  });

  let pageDecrementBtn = null;
  if (minPageNumberLimit !== 0) {
    pageDecrementBtn = (
      <div className="blueborder_btn  bg_borderred" onClick={handlePrev}>
        &hellip;
      </div>
    );
  }

  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = (
      <div className="blueborder_btn  bg_borderred" onClick={handleNext}>
        &hellip;
      </div>
    );
  }

  const handleClickView = () => {
    if(row[0]?.loan_status === "dealerinfopending")navigate(`/admin/dealerinfopending`)
    else if (row[0]?.loan_status === "pending")navigate(`/admin/pending`)
    else navigate(`/admin/approved`);
  };

  const handleResend = async (email) => {
    const body = { email: email };
    await Post("loanstage/flow", body, "admin").then((res) => {
      if (res.status == 200) {
        toast.success(res.message);
      }
    });
  };

  const handleSendBankEmail = async (loanId) => {
    await AuthGet(`plaid/connect-bank/${loanId}`, "admin")
      .then((res) => {
        if (res["statusCode"] == 200) {
          toast.success("Mail sent successfully");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSendOffers = async (loan_id) => {
    await AuthPost(`loan/send-acceptance/${loan_id}`, {}, "admin")
      .then((res) => {
        if (res.statusCode === 200) {
          toast.success("Offers sent successfully");
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => {
        console.log("Put error: ", err);
        toast.error(err.message);
      });
  };

  return (
    <>
      <div className="col-md-12">
        <div className="whitebg w_fit">
          <ul className="tracker_head">
            {page === "dashboard" ? (
              <>
                <li
                  onClick={() => handleDealChange("App")}
                  className={
                    toggle === "App"
                      ? "active cursorpointer titleText"
                      : "cursorpointer titleText"
                  }
                >
                  {row[0]?.loan_status === "dealerinfopending"
                    ? "Dealer Info"
                    : "Opportunities"}
                </li>
                <li
                  onClick={() => handleDealChange("Fund")}
                  className={
                    toggle === "Fund"
                      ? "active cursorpointer titleText"
                      : "cursorpointer titleText"
                  }
                >
                  Deals
                </li>
              </>
            ) : page === "Waiting " ? (
              <li className="active cursorpointer titleText">
                Prospects Application Details
              </li>
            ) : page === "Pending " ? (
              <li className="active cursorpointer titleText">
                Opportunities Application Details
              </li>
            ) : page === "Approved " ? (
              <li className="active cursorpointer titleText">
                Deals Application Details
              </li>
            ) : page === "Fundingcontract " ? (
              <li className="active cursorpointer titleText">
                Fund Disbursed Application Details
              </li>
            ) : page === "Dealerinfopending " ? (
              <li className="active cursorpointer titleText">Dealer Info</li>
            ) : null}
          </ul>
          {/* search and filter */}
          <div className="displayFlex AlignItemCenter flexEnd gap20">
            <div class="inputGroup">
              <input
                type="text"
                placeholder="Search"
                onChange={(e) => optimizedFn(e.target.value)}
              />

              <div class="input-group-append">
                <span class="inputgroupText">
                  <img src={searchicon} />
                </span>
              </div>
            </div>
            <div className="">
              <Dropdown className="filter_btn">
                <Dropdown.Toggle>
                  <div>
                    <img src={filtericon} />
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu className="bgnone">
                  <Dropdown.Item href="#/action-1">Action</Dropdown.Item>

                  <Dropdown.Item href="#/action-2">
                    Another action
                  </Dropdown.Item>

                  <Dropdown.Item href="#/action-3">
                    Something else
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          {/* table */}
          <div className="table_responsive col-md-12   trackertable_scroll">
            {row?.length > 0 ? (
              <table className="trackertable">
                <tbody>
                  {/* table-head-start */}
                  <tr>
                    {column?.map((e, i) => {
                      return (
                        <th
                          style={{ minWidth: `${e.width}px` }}
                          hidden={e?.hidden}
                          key={i}
                        >
                          {e?.heading()}
                        </th>
                      );
                    })}
                  </tr>
                  {/* table-head-end */}
                  {/* table-data-start */}

                  {row?.map((rowData, i) => {
                    return (
                      <>
                        <tr
                          className="cursorpointer"
                          onClick={() => handleDropDown(rowData)}
                          key={i}
                        >
                          {column?.map((columnData, j) => {
                            return (
                              <>
                                <td
                                  style={{ minWidth: `${columnData?.width}px` }}
                                  key={j}
                                  hidden={columnData?.hidden}
                                >
                                  {columnData?.cell(rowData, start + i)}
                                </td>
                              </>
                            );
                          })}
                        </tr>
                        <tr>
                          {rowData.isDrop && (
                            <td colSpan={6}>
                              <LoanStageStatus
                                data={rowData}
                                loanParams={loanParams}
                                handleResend={handleResend}
                                handleSendBankEmail={handleSendBankEmail}
                                handleSendOffers={handleSendOffers}
                              />
                            </td>
                          )}
                        </tr>
                      </>
                    );
                  })}

                  {/* <tr>
            <td>02 jan 2023</td>
            <td>LON_39</td>
            <td>
              NAME <span>(Mini Cooper)</span>
            </td>
            <td>
              {initialState.currency}31{" "}
              <span>({initialState.currency}1.2. Per.mo)</span>
            </td>
            <td>
              <div className="status_percent">
                <div className="pendingprogress">Pending 70%</div>
              </div>
            </td>
            <td>
              <div className="cursorpointer">
                <img src={dropdownicon} />
              </div>
            </td>
          </tr>

          <tr>
            <td>02 jan 2023</td>
            <td>LON_39</td>
            <td>
              NAME <span>(Mini Cooper)</span>
            </td>
            <td>
              {initialState.currency}31{" "}
              <span>({initialState.currency}1.2. Per.mo)</span>
            </td>
            <td>
              <div className="status_percent">
                <div className="pendingprogress completeprogress">
                  Completed 100%
                </div>
              </div>
            </td>
            <td>
              <div className="cursorpointer">
                <img src={dropdownicon} />
              </div>
            </td>
          </tr>
          <tr>
            <td>02 jan 2023</td>
            <td>LON_39</td>
            <td>
              NAME <span>(Mini Cooper)</span>
            </td>
            <td>
              {initialState.currency}31{" "}
              <span>({initialState.currency}1.2. Per.mo)</span>
            </td>
            <td>
              <div className="status_percent">
                <div className="pendingprogress">Pending 70%</div>
              </div>
            </td>
            <td>
              <div className="cursorpointer active_dropdown">
                <img src={dropdownicon} />
              </div>
            </td>
          </tr> */}
                  {/* table-data-end */}
                </tbody>
              </table>
            ) : (
              <h4>No data available</h4>
            )}

            {page === "dashboard" && (
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  justifyContent: "center",
                  marginTop: "10px",
                  height: "50px",
                }}
              >
                <button
                  onClick={handleClickView}
                  className="blueborder_btn   bg_darkred mb_15"
                >
                  View All
                </button>
              </div>
            )}

            {/* <LoanStageStatus data={row} /> */}
          </div>
          {/* Pagination Section */}
          {page !== "dashboard" && row?.length > 0 && (
            <div className="displayFlex textAlignCenter spaceBetween mt30">
              <div>
                Showing {start} to {end} of {totalCount} entries
              </div>
              <ul className="pageNumbers displayFlex textAlignCenter spaceBetween">
                <div>
                  <button
                    className={
                      currentPage === 1
                        ? "blue_btn disabled_pagebtn"
                        : "blue_btn bg_darkred"
                    }
                    onClick={handlePrev}
                  >
                    Prev
                  </button>
                </div>
                {pageDecrementBtn}
                {renderPageNumbers}
                {pageIncrementBtn}
                <div>
                  <button
                    onClick={handleNext}
                    className={
                      currentPage === pages.length
                        ? "blue_btn disabled_pagebtn"
                        : "blue_btn bg_darkred"
                    }
                  >
                    Next
                  </button>
                </div>
              </ul>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default LoanStagetable;
