import React, { useState } from "react";
import { useStateValue } from "../../Context/StateProvider";
import { useNavigate } from "react-router-dom";

const LoanStageStatus = ({
  data,
  loanParams,
  handleResend,
  handleSendBankEmail,
  handleSendOffers,
}) => {
  const statusList = [
    "kyc_PI_status",
    "kyc_work_status",
    "kyc_income_status",
    "kyc_property_status",
    "bank_status",
    "agreement_status",
  ];

  const navigate = useNavigate();
  const [initialState, dispatch, authState] = useStateValue();
  const [statusBar, setStatusbar] = useState(data?.loan_stage_status[0]);
  console.log("statusBar::: ", statusBar);

  const handleLoanData = (loan_id) => {
    sessionStorage.setItem("loan_id", loan_id);
    sessionStorage.setItem("procedure_date", JSON.stringify({}));
    navigate(
      `/admin/${
        data?.loan_status === "approvedcontract"
          ? "approved"
          : data?.loan_status === "canceled"
          ? "denied"
          : data?.loan_status === "archive"
          ? "archived"
          : data?.loan_status
      }/${loan_id}`
    );
  };
  const statusValues = statusList.map((status) => statusBar[status]);
  console.log("statusValues::: ", statusValues);
  return (
    <div className="row p_16">
      {/* left side details */}
      <div className="col-md-6 vehicle_details w_100tabview">
        <table className="trackerdetails mt_48">
          <tr>
            <th className="displayFlex spaceBetween">
              Vehicle <span> :</span>
            </th>
            <td className=" ">
              {data?.vehicle && data?.vehicle !== " " ? data?.vehicle : "NA"}{" "}
            </td>
          </tr>
          <tr>
            <th className="displayFlex spaceBetween">
              Purchase Price
              <span> :</span>
            </th>
            <td className=" ">
              {initialState?.currency}
              {data?.purchase_price && data?.purchase_price !== " "
                ? data?.purchase_price
                : "NA"}
            </td>
          </tr>
          <tr>
            <th className="displayFlex spaceBetween">
              Loan Terms <span> :</span>
            </th>
            <td className=" ">
              {data?.duration && data?.duration !== " " ? `${data?.duration} Months` : "NA"}
            </td>
          </tr>
          <tr>
            <th className="displayFlex spaceBetween">
              APR <span> :</span>
            </th>
            <td className=" ">
              {data?.interestrate ? data?.interestrate + " %" : "NA"}{" "}
            </td>
          </tr>
        </table>

        <table className="trackerdetails mt_48">
          <tr>
            <th className="displayFlex spaceBetween">
              Email <span> :</span>
            </th>
            <td className=" ">{data?.email ? data?.email : "NA"}</td>
          </tr>

          <tr>
            <th className="displayFlex spaceBetween">
              Address <span> :</span>
            </th>
            <td className=" ">
              {data?.street_address ? data?.street_address : "NA"}
            </td>
          </tr>

          <tr>
            <th className="displayFlex spaceBetween">
              Driver License
              <span> :</span>
            </th>
            <td className=" ">
              {data?.license_number ? data.license_number : "NA"}
            </td>
          </tr>
        </table>
        {/* <div className="textAlignRight mt_20">
          <button className="blue_btn">Print</button>
        </div> */}
      </div>
      {/* right side details */}
      <div className="col-md-6 w_100tabview">
        <div className="table_responsive pb_24">
          <ul class="statusTracking">
            {statusList?.map((el, i) => (
              <li>
                {console.log("el::: ", el)}
                <div>
                  <button
                    className={
                      statusBar[el] === true
                        ? "circle completed"
                        : "circle incomplete_circle"
                    }
                  ></button>
                </div>
                <div className="w100per">
                  <button
                    className={
                      statusBar[el] === true
                        ? "infoDetail"
                        : "infoDetail incompleteinfo"
                    }
                  >
                    <span className="track_icon"></span>
                    {el === "bank_status"
                      ? "Bank Status"
                      : el === "agreement_status"
                      ? "Agreement Status"
                      : el === "kyc_PI_status"
                      ? "KYC PI Status"
                      : el === "kyc_income_status"
                      ? "KYC Income Status"
                      : el === "kyc_property_status"
                      ? "KYC Property Status"
                      : el === "kyc_work_status"
                      ? "KYC Work Status"
                      : el === "Inactivedeals"
                      ? "Inactive Deals"
                      : ""}
                  </button>
                </div>
                <div>
                  <button
                    onClick={
                      el === "kyc_PI_status"
                        ? () => handleResend(data?.email)
                        : el === "kyc_income_status"
                        ? () => handleResend(data?.email)
                        : el === "kyc_work_status"
                        ? () => handleResend(data?.email)
                        : el === "kyc_property_status"
                        ? () => handleResend(data?.email)
                        : el === "bank_status"
                        ? () => handleSendBankEmail(data?.loan_id)
                        : el === "agreement_status"
                        ? () => handleSendOffers(data?.loan_id)
                        : null
                    }
                    disabled={
                      (loanParams === "waiting" && el === "agreement_status") ||
                      (loanParams === "dealerinfopending" &&
                        el === "agreement_status") ||
                      loanParams === "archived" ||
                      loanParams === "denied" ||
                      statusBar[el] === true
                    }
                    className={
                      (loanParams === "waiting" && el === "agreement_status") ||
                      (loanParams === "dealerinfopending" &&
                        el === "agreement_status") ||
                      loanParams === "archived" ||
                      loanParams === "denied" ||
                      statusBar[el] === true
                        ? "statusBtn"
                        : "statusBtn incomplete_status_btn"
                    }
                  >
                    {statusBar[el] === true ? "Completed" : "Send"}
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="displayFlex flexEnd">
          {/* <div className=" mr_14 ">
            <button className="blue_btn">Pre Approved</button>
          </div> */}
          <div className="  ">
            <button
              onClick={() => handleLoanData(data?.loan_id)}
              className="blueborder_btn  bg_borderred"
            >
              View Application
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoanStageStatus;
