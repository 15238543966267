import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import form_buildericon from "../../assets/images/form_buildericon.svg";
import landing_pageicon from "../../assets/images/landing_pageicon.svg";
import start_now_icon from "../../assets/images/start_now_icon.svg";
import dashboardicon from "../../assets/images/dashboard_icon.svg";
import incompleteicon from "../../assets/images/incomplete-icon.svg";
import handshake from "../../assets/images/handshake.svg";
import dealer_info from "../../assets/images/dealer_info.svg";
import opportunities from "../../assets/images/pending-application-icon.svg";
import dealicon from "../../assets/images/deal-icon.svg";
import funddisbursedicon from "../../assets/images/fund-disbursed.svg";
import logouticon from "../../assets/images/logout-icon.svg";
import inactiveicon from "../../assets/images/inactive-icon.svg";
import aanoticeicon from "../../assets/images/aa-notice.svg";
import usersicon from "../../assets/images/users-icon.svg";
import { toast } from "react-hot-toast";
import contacticon from "../../assets/images/contact-icon.svg";
import addbank from "../../assets/images/addBank_icon_white.svg";
import manageicon from "../../assets/images/manage-icon.svg";
import logicon from "../../assets/images/log-icon.svg";
import decisionEngineIcon from "../../assets/images/decision-engine-icon.svg";
import { useEffect } from "react";
import DecisionEngine from "../../Admin/Pages/DecisionEngine";
import { AuthGet } from "../../common_var/httpService";
import { useStateValue } from "../../Context/StateProvider";
import SelectLoanAmount from "../../Templates/SelectLoanAmount/SelectLoanAmount";
import StartApplication from "../../Pages/StartApplication/StartApplication";

const Adminsidebar = ({ goToFormBuilder }) => {
  const navigate = useNavigate();
  const [{ selectLoanPopup, emailPhonePopup, isInviteOptionInAdmin }, dispatch] = useStateValue();
  const [show, setShow] = useState(true);
  const [sideBar, setSideBar] = useState([]);
  const [showProvidersOptions, setShowProvidersOptions] = useState(false);
  const queryParams = useLocation();
  const loanParams = queryParams.pathname.split("/");
  const domain = window.location.href.split("/admin")[0];

  let pages = JSON.parse(sessionStorage.getItem("pages"));

  let role = "";
  if (sessionStorage.getItem("resuser")) {
    role = JSON.parse(sessionStorage.getItem("resuser"));
  }
  // console.warn(role)

  const logout = () => {
    navigate("/admin/login");
    sessionStorage.clear();
  };

  const showSideBar = () => {
    setShow(!show);
  };

  const getAppLink = async () => {
    try {
      let res = await AuthGet(`provider-management`, "admin");
      if (res?.allPractice?.length > 0) {
         role.role==4?window.open(domain+'/'+res?.allPractice?.[0]?.practicename):window.open(domain);
      } else {
        navigate("/admin/practice-management");
        toast.error("No dealer Available");
      }
    } catch (error) {
      console.log("error::: ", error);
    }
  };

  const startApp = () => {
    window.open("/select-loan", "_blank");
  };

  const getWorkflow = async () => {
    await AuthGet(`heads-up-display/fetch-steps`, "admin")
      .then((res) => {
        if (res["statusCode"] == 200) {
          setSideBar(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getWorkflow();
  }, []);

  let Admin = JSON.parse(sessionStorage.getItem("resuser")).maininstallerid;

  const checkRoles = (tabs) => {
    let IsRoleAvailable = pages.some((page) => page.name === tabs);
    // let a = pages.some((page) => console.log('pages::: ', page.name));
    return IsRoleAvailable;
  };

  useEffect(() => {
    if (
      loanParams[2] === "practice-management" ||
      loanParams[2] === "loan-product-mapping" ||
      loanParams[2] === "practice-admin"
    ) {
      setShowProvidersOptions(true);
    } else if (
      loanParams[2] != "practice-management" ||
      loanParams[2] === "loan-product-mapping"
    ) {
      setShowProvidersOptions(false);
    }

    return () => {
      setShowProvidersOptions(false);
    };
  }, [loanParams[2]]);

  return (
    <div className="AdminSidemenu">
      <div className="admin_sidebarr">
        <div className=" sidebarmenuTitle">
          Admin Portal
          <div id="sidenavToggler " className="cursorpointer sidenavToggler">
            <i
              className="far fa-chevron-left"
              onClick={() => showSideBar()}
            ></i>
          </div>
        </div>

        <div className="menuBlock ">
          <ul>
            {checkRoles("dashboard") && (
              <li
                onClick={() => navigate("/admin/dashboard")}
                className={
                  loanParams[2] === "dashboard" ? "menuActive" : "menuIcon"
                }
              >
                <div className="menuIcon">
                  {" "}
                  <img src={dashboardicon} />
                </div>
                <div> Heads Up Display</div>
              </li>
            )}

            {checkRoles("waiting") && (
              <li
                onClick={() => navigate("/admin/waiting")}
                className={
                  loanParams[2] === "waiting" ? "menuActive" : "menuIcon"
                }
              >
                <div className="menuIcon">
                  {" "}
                  <img src={incompleteicon} />
                </div>
                <div> Prospects</div>
              </li>
            )}


            {checkRoles("dealerinfopending") && (
              <li
                onClick={() => navigate("/admin/dealerinfopending")}
                className={
                  loanParams[2] === "dealerinfopending" ? "menuActive" : "menuIcon"
                }
              >
                <div className="menuIcon">
                  {" "}
                  <img src={dealer_info} />
                </div>
                <div> Dealer Info Pending</div>
              </li>
            )}

            {checkRoles("pending") && (
              <li
                onClick={() => navigate("/admin/pending")}
                className={
                  loanParams[2] === "pending" ? "menuActive" : "menuIcon"
                }
              >
                <div className="menuIcon">
                  {" "}
                  <img src={opportunities} className=" " />
                </div>
                <div> Opportunities</div>
              </li>
            )}

            {checkRoles("approved") && (
              <li
                onClick={() => navigate("/admin/approved")}
                className={
                  loanParams[2] === "approved" ? "menuActive" : "menuIcon"
                }
              >
                <div className="menuIcon">
                  {" "}
                  <img src={dealicon} className=" " />
                </div>
                <div> Deals</div>
              </li>
            )}

            {checkRoles("fundingcontract") && (
              <li
                onClick={() => navigate("/admin/fundingcontract")}
                className={
                  loanParams[2] === "fundingcontract"
                    ? "menuActive"
                    : "menuIcon"
                }
              >
                <div className="menuIcon">
                  {" "}
                  <img src={funddisbursedicon} className=" " />
                </div>
                <div> Fund Disbursed </div>
              </li>
            )}

            {checkRoles("archived") && (
              <li
                onClick={() => navigate("/admin/archived")}
                className={
                  loanParams[2] === "archived" ? "menuActive" : "menuIcon"
                }
              >
                <div className="menuIcon">
                  {" "}
                  <img src={inactiveicon} className=" " />
                </div>
                <div> Inactive Deals </div>
              </li>
            )}

            {checkRoles("denied") && (
              <li
                onClick={() => navigate("/admin/denied")}
                className={
                  loanParams[2] === "denied" ? "menuActive" : "menuIcon"
                }
              >
                <div className="menuIcon">
                  {" "}
                  <img src={aanoticeicon} className=" " />
                </div>
                <div> AA Notice </div>
              </li>
            )}
            {checkRoles("startApp") && (
              <li onClick={startApp} className="menuIcon">
                <div className="menuIcon">
                  {" "}
                  <img src={start_now_icon} className=" " />
                </div>
                <div> Start Now</div>
              </li>
            )}
          </ul>
          <div className=" ">
            <div className=" ">
              {(checkRoles("practice-admin") ||
                checkRoles("loan-product-mapping") ||
                checkRoles("practice-management") ||
                checkRoles("users") ||
                checkRoles("patients") ||
                checkRoles("auditlog") ||
                checkRoles("decision_engine") ||
                checkRoles("go_to_brand_page") ||
                checkRoles("loan_flow_Builder")) && (
                  <div className="othertitle"> Configurations </div>
                )}
              <ul>
                {checkRoles("users") && (
                  <li
                    onClick={() => navigate("/admin/users")}
                    className={
                      loanParams[2] === "users" ? "menuActive" : "menuIcon"
                    }
                  >
                    <div className="menuIcon">
                      {" "}
                      <img src={usersicon} className=" " />
                    </div>
                    <div> Portal Users </div>
                  </li>
                )}

                {checkRoles("patients") && (
                  <li
                    onClick={() => navigate("/admin/patients")}
                    className={
                      loanParams[2] === "patients" ? "menuActive" : "menuIcon"
                    }
                  >
                    <div className="menuIcon">
                      {" "}
                      <img src={contacticon} className=" " />
                    </div>
                    <div> Contacts</div>
                  </li>
                )}

                {checkRoles("add-bank") && (
                  <li
                    onClick={() => navigate("/admin/add-bank")}
                    className={
                      loanParams[2] === "add-bank" ? "menuActive" : "menuIcon"
                    }
                  >
                    <div className="menuIcon">
                      {" "}
                      <img src={addbank} className=" " />
                    </div>
                    <div> Add bank</div>
                  </li>
                )}

                {(checkRoles("practice-admin") ||
                  checkRoles("loan-product-mapping") ||
                  checkRoles("practice-management")) && (
                    <div>
                      <ul
                        className={
                          showProvidersOptions ||
                            loanParams[2] === "practice-management"
                            ? "menuActive"
                            : "menuIcon"
                        }
                      >
                        <li
                          onClick={() =>
                            setShowProvidersOptions(!showProvidersOptions)
                          }
                          className="menuIcon"
                          style={{ display: "flex" }}
                        >
                          <div className="menuIcon">
                            {" "}
                            <img src={manageicon} className=" " />
                          </div>
                          <div>Dealer </div>
                        </li>
                        {showProvidersOptions && (
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            {checkRoles("practice-management") && (
                              <div
                                style={{ marginLeft: "15px" }}
                                onClick={() => setShowProvidersOptions(true)}
                              >
                                <li
                                  onClick={() =>
                                    navigate("/admin/practice-management")
                                  }
                                  className={
                                    loanParams[2] === "practice-management"
                                      ? "menuActive"
                                      : "menuIcon"
                                  }
                                >
                                  <div className="menuIcon">
                                    {" "}
                                    <img src={manageicon} className=" " />
                                  </div>
                                  <div> Manage Dealer </div>
                                </li>
                              </div>
                            )}

                            {checkRoles("loan-product-mapping") && (
                              <div style={{ marginLeft: "15px" }}>
                                <li
                                  onClick={() =>
                                    navigate("/admin/loan-product-mapping")
                                  }
                                  className={
                                    loanParams[2] === "loan-product-mapping"
                                      ? "menuActive"
                                      : "menuIcon"
                                  }
                                >
                                  <div className="menuIcon">
                                    {" "}
                                    <img src={manageicon} className=" " />
                                  </div>
                                  <div> Loan Product Mapping </div>
                                </li>
                              </div>
                            )}
                            {checkRoles("practice-admin") && (
                              <div style={{ marginLeft: "15px" }}>
                                <li
                                  onClick={() =>
                                    navigate("/admin/practice-admin")
                                  }
                                  className={
                                    loanParams[2] === "practice-admin"
                                      ? "menuActive"
                                      : "menuIcon"
                                  }
                                >
                                  <div className="menuIcon">
                                    {" "}
                                    <img src={manageicon} className=" " />
                                  </div>
                                  <div> Dealer Users </div>
                                </li>
                              </div>
                            )}
                          </div>
                        )}
                      </ul>
                    </div>
                  )}
                {checkRoles("auditlog") && (
                  <li
                    onClick={() => navigate("/admin/auditlog")}
                    className={
                      loanParams[2] === "auditlog" ? "menuActive" : "menuIcon"
                    }
                  >
                    <div className="menuIcon">
                      {" "}
                      <img src={logicon} className=" " />
                    </div>
                    <div> Trace Actions</div>
                  </li>
                )}

                {checkRoles("decision_engine") && <DecisionEngine />}
                {checkRoles("go_to_brand_page") && isInviteOptionInAdmin && (
                  <div>
                    <ul>
                      <li onClick={getAppLink} className="menuIcon">
                        <div className="menuIcon">
                          {" "}
                          <img src={landing_pageicon} className=" " />
                        </div>
                        <div> Go To Brand Page</div>
                      </li>
                    </ul>
                  </div>
                )}

                {checkRoles("loan_flow_Builder") && (
                  <div>
                    <ul>
                      <li onClick={goToFormBuilder} className="menuIcon">
                        <div className="menuIcon">
                          {" "}
                          <img src={form_buildericon} className=" " />
                        </div>
                        <div>Loan Flow Builder</div>
                      </li>
                    </ul>
                  </div>
                )}

                <div>
                  <ul></ul>
                </div>

                {selectLoanPopup && <SelectLoanAmount />}
                {emailPhonePopup && <StartApplication />}
              </ul>
            </div>
          </div>
        </div>

        <div style={{ clear: "both" }}></div>
      </div>
    </div>
  );
};

export default Adminsidebar;