import React, { useState, useEffect, useRef } from 'react';
import { useStateValue } from '../../Context/StateProvider';
import { useLocation } from 'react-router-dom';
import { AuthGet, AuthPost, AuthFile } from "../../common_var/httpService";
import BootstrapTable from 'react-bootstrap-table-next';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import CustomModal from '../../Components/Modal/Modal';
import { env_variables } from '../../common_var/env';
import Toast from '../../Components/Toaster/Toast';
import { toast } from 'react-hot-toast';
import Modal from "react-bootstrap/Modal";
import SmalldeleteIcon from "../../assets/images/smallDelete_icon.svg";
import PdfIcon from "../../assets/images/pdf_icon.svg";
import DownwardIcon from "../../assets/images/downward_icon.svg";
import SmalleyeIcon from "../../assets/images/smalleye_icon.svg";
import ClouduploadIcon from "../../assets/images/cloudUpload_icon.svg";
import { useFormik } from 'formik';
import * as Yup from 'yup';

const UploadDoc = () => {

    const [resfile, setresdoc] = useState([]);
    const [userConsentDoc, setUserConsentDoc] = useState([]);
    console.log('userConsentDoc::: ', userConsentDoc);
    const [initialState, dispatch, authState, setAuthState] = useStateValue();
    const [upfile, setUpfile] = useState([]);
    const [doctype, setDoctype] = useState([]);
    const [filedoc, setFiledoc] = useState([]);
    const [show, setShow] = useState(false);
    const [confirmation, setConfirmation] = useState(false)
    const [deleteFile, setDeleteFile] = useState({})
    const [dynamicClass, setDynamicClass] = useState(false);
    const queryParams = useLocation()
    let params = queryParams.pathname.split('/');
    const loanId = params[params.length - 1];
    console.log('loanId::: ', loanId);
    const loanStage = params[params.length - 2];
    const handleClose = () => {
        setUpfile([])
        formikUploadDoc.resetForm();
        setShow(false)
    };
    const handleShow = () => setShow(true);
    const inpImg = useRef("");

    const get = async (loan_id) => {
        await AuthGet(`loanstage/${loan_id}/${loanStage}`, "admin")
            .then((res) => {
                //console.log(res);
                if (res["statusCode"] == 200) {
                    console.log("Documents: ", res);
                    let userDocument = [];
                    userDocument.push(...res.data.userDocument);
                    // console.log("All docs: ", userDocument);
                    for (let i = 0; i < userDocument.length; i++) {
                        if (userDocument[i].filepath) {
                            userDocument[i].id = `${userDocument[i].filepath}__${new Date().getTime()}`;
                        }
                        else {
                            userDocument[i].id = `${userDocument[i].orginalfilename}__${new Date().getTime()}`;
                        }
                    }
                    console.log("document data: ", userDocument);
                    // setUserData(allDocuments);

                    let consentDocument = [];
                    consentDocument.push(...res.data.document);
                    for (let i = 0; i < consentDocument.length; i++) {
                        if (consentDocument[i].filepath) {
                            consentDocument[i].id = `${consentDocument[i].filepath}__${new Date().getTime()}`;
                        }
                        else {
                            consentDocument[i].id = `${consentDocument[i].orginalfilename}__${new Date().getTime()}`;
                        }
                    }
                    console.log("document data: ", consentDocument);
                    // setresdoc(userDocument);
                    // setUserConsentDoc(consentDocument);
                } else {
                    alert(res["message"]);
                }
            })
            .catch((error) => {
                console.log("Document error---->", error);
            });
    };

    // All Document get 

    const getdocument = async () => {
        await AuthGet(`document-vault/fetch-all-documents/${loanId}`, "admin")
          .then((res) => {
            //console.log(res);
            if (res["statusCode"] == 200) {
              setresdoc(res.data.files);
              setUserConsentDoc(res.data.userConsentDoc);
            } else {
              alert(res["message"]);
            }
          })
          .catch((error) => {
            console.log("error---->", error);
          });
      };

    const documentDelete = async () => {
        // console.log("Document delete Data: ", data);
        let { filename, documenttype } = deleteFile;
        await AuthPost(`savefiles/deletedocument/${loanId}`,
            { fileName: filename, type: documenttype }, "admin")
            .then((res) => {
                //console.log(res);
                if (res["statusCode"] == 200) {
                    toast.success(res['message']);
                    setConfirmation(!confirmation)
                    setDeleteFile({})
                    getdocument()
                } else {
                    toast.success(res['message']);
                }
            })
            .catch((error) => {
                console.log("error---->", error);
            });
    }

    const removeDocument = (name, index) => {
        console.log(name)
        setFiledoc(filedoc.filter((item, i) => (i != index)));
    }
    const handleimage = (e) => {
        setDynamicClass(false)
        console.log("Inside handle image");
        console.log(e.target.files);
        const dimg = [e.target.files[0]]
        setUpfile(e.target.files[0]);
        console.log(dimg)
        if (e.target.files[0]) {
            handleShow();
        }
        e.target.value = ''
    };
    console.log(upfile);

    const dropDoctype = (e) => {
        console.log(e.target.value);
        setDoctype(e.target.value);
    };

    const formikUploadDoc = useFormik({
        initialValues: ({
            docType: ""
        }),
        validationSchema: Yup.object({
            docType: Yup.string()
                .required('Please select document type')
        }),
        onSubmit: (values) => {
            console.log("Doc type: ", values.docType);
            if (values) {
                // setDoctype(values.docType);
                handleClose();
                subDoctype(values.docType);
                formikUploadDoc.resetForm();
            }
        }
    })

    const subDoctype = (doctype) => {
        console.log("Doc Type: ", doctype);
        if (doctype !== "") {
            let data = {
                files: upfile,
                documentsTypes: doctype,
            };

            setFiledoc([...filedoc, data]);
            handleClose();
        } else {
            console.log("Doc type not selected!");
            alert("please select doc type");
        }
    };

    console.log(filedoc);

    const completed = async () => {
        let url = `savefiles/uploads`;
        let id = sessionStorage.getItem("loan_id");
        let user_id = sessionStorage.getItem("UserId");
        console.log(filedoc);
        const formData = new FormData();
        formData.append("loan_id", id);
        formData.append('user_id', user_id);
        if (filedoc.length > 0) {
            setDynamicClass(false)
            for (var i = 0; i < filedoc.length; i++) {
                if (filedoc[i].documentsTypes === "") {
                    console.log("file doc---->", filedoc[i].documentsTypes);
                    filedoc[i].documentsTypes = "";
                    alert(
                        `cannot upload, select document type for` + filedoc[i].documentsTypes
                    );
                    return false;
                }
                formData.append("type[]", filedoc[i].documentsTypes);
                formData.append("files[]", filedoc[i].files);
            }
            await AuthFile(`${url}`, formData, "admin")
                .then((res) => {
                    console.log(res);
                    if (res.statusCode == 200) {
                        setFiledoc([])
                        getdocument()
                        toast.success("Document Uploaded Sucessfully");
                    }
                    if (res.data != undefined) {
                        if (res.data.statusCode >= 400 && res.data.statusCode <= 410) {
                            toast.error(res.data.message);
                        }
                    }
                    console.log("res---->", res);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        else {
            toast.error('Please Upload a file')
            setDynamicClass(true)
        }
    };

    useEffect(() => {
        getdocument()
        let loan_id = sessionStorage.getItem("loan_id");
        console.log("Document loan id: ", loan_id);
        if (loan_id) {
            get(loan_id);
           
        }

        
    }, []);

    const view = (filename, key) => {
        console.log(filename);
        console.log("Admin key: ", key);
        if (filename) {
            filename = filename?.split("/");
            filename = filename[filename.length - 1];
            filename = `${loanId}/${filename}`;

            window.open(
                key == 'admin' ? process.env.REACT_APP_ADMIN_API_URL + "/files/save/" + filename : process.env.REACT_APP_BORROWER_API_URL + "/files/download/" + filename,
                "_blank"
            );
        }
    };

    const openDeletionModel = ( document ) => {
        setDeleteFile(document)
        setConfirmation(!confirmation)
    }

    return (
        <>
            <Toast />
            <div className="whiteFrame p16 mb_24">
                <h3 className="heading18 fontWeight500 mb_20">
                    Loan Documents
                </h3>

                <div className="row mb_24">
                    {userConsentDoc?.map((d, i) => {

                        return (
                            <div className="col-md-4 mb10px" key={i}>
                                <div className="fileDoc_Item">
                                    <div className="fileDoc_left">
                                        <div>
                                            <div className="greenfolder"></div>
                                        </div>
                                        <div>
                                            <h5>{d.name}</h5>
                                            {/* <p>128 Kb</p> */}
                                        </div>
                                    </div>
                                    <div>
                                        <button className="docuActionButton docuActionButtonBlack" onClick={() => { view(d.filepath, 'admin') }}>
                                            <img src={DownwardIcon} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>

            <div className="whiteFrame p16 mb_24">
                <h3 className="heading18 fontWeight500 mb_20">Documents Uploaded</h3>

                <div className="row">
                    <div className="row mb_24">
                        {resfile?.map((d, i) => {

                            return (
                                <div className="col-md-6" key={i}>
                                    <div className="fileDoc_Item">
                                        <div className="fileDoc_left">
                                            <div>
                                                <div className="greenfolder"></div>
                                            </div>
                                            <div>
                                                <h5>{d.documenttype}</h5>
                                                <small style={{ fontSize: "12px" }}>{d.originalname}</small>
                                                {/* <p>128 Kb</p> */}
                                            </div>
                                        </div>
                                        <div className="uploadDocAction">
                                            <button className="viewBtn mb_4" onClick={() => { view(d.filename, 'admin') }}>
                                                <img src={SmalleyeIcon} /> <span>View</span>
                                            </button>
                                            <button className="deleteBtn" onClick={() => openDeletionModel(d)}>
                                                <img src={SmalldeleteIcon} /> <span>Delete</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            );
                        })}
                    </div>
                </div>
            </div>

            <div className="whiteFrame mb_24">
                <div className="whiteFrameHeader">
                    <h3 className="heading18 fontWeight600">Upload More</h3>
                </div>
                <div className="p16">
                    <div className="textAlignCenter mb_24">
                        <label htmlFor="document_img" className={dynamicClass ? "label-file-upload_err" : "Chooseuploadbox"}>
                            <img src={ClouduploadIcon} className="mb_12" />
                            <input
                                id="document_img"
                                name="image"
                                type="file"
                                onChange={(e) => handleimage(e)}
                                accept=".jpg,.jpeg,.png,application/pdf"
                                hidden
                            />
                            <h2>Click to upload</h2>
                        </label>
                    </div>
                    <div className="spreadsheetWrapper mb_24">
                        <table>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>File Name</th>
                                    <th>File Format</th>
                                    <th>File size</th>
                                    <th>Document Type</th>
                                    <th>Action</th>
                                </tr>
                            </thead>

                            <tbody>
                                {(filedoc !== null && filedoc.length > 0) ? (
                                    filedoc?.map((data, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>
                                                    <div className="greenfolder"></div>
                                                </td>
                                                <td>{data.files.name.split(".")[0]}</td>

                                                <td>{data.files.name.split(".")[1]}</td>

                                                <td>{(data.files.size / 1024).toFixed(2)} KB</td>

                                                <td>{data.documentsTypes}</td>
                                                <td>
                                                    <div className="uploadDocAction">
                                                        <button className="deleteBtn" onClick={() => removeDocument(data.files.name, i)}>
                                                            <img src={SmalldeleteIcon} /> <span>Delete</span>
                                                        </button>
                                                    </div>
                                                </td>

                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <td className="textAlignCenter" colSpan={5}>
                                            <h5 className="nodataText">No file selected</h5>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className="textAlignCenter">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={completed}
                        >
                            Upload
                        </button>
                    </div>
                </div>
            </div>

            <div>
                <Modal
                    show={show}
                    onHide={handleClose}
                    className="addnewpopup"
                    backdrop="static"
                >
                    <Modal.Header closeButton>
                        {/* <Modal.Title>Select Document Type</Modal.Title> */}
                        <h4 className="heading24_bold">Select Document Type</h4>
                        {/* <div className="DisplayFlex flexWrap_wrap AlignItem_center mobilegap_12">
                    <button className="emptyBtn">
                        <img src="/images/modal_closeIcon.svg" />
                    </button>
                    </div> */}
                    </Modal.Header>
                    <div className="modelScroll">
                        <Modal.Body>
                            <form onSubmit={formikUploadDoc.handleSubmit}>
                                <div>
                                    <select
                                        name="docType"
                                        id="docType"
                                        // onChange={dropDoctype}
                                        className="MainInput"
                                        value={formikUploadDoc.values.docType}
                                        onChange={formikUploadDoc.handleChange}
                                        onBlur={formikUploadDoc.handleBlur}
                                    >
                                        <option value="">Select Document Type</option>
                                        <option value="Government Issued ID">
                                            Government Issued ID
                                        </option>
                                        <option value="Paystub">Paystub</option>
                                        <option value="Income Document">Income Document</option>
                                        <option value="Driver License">Driver License</option>
                                        <option value="Bank Statement">Bank Statement</option>
                                        <option value="Proof of Ownership">Proof of Ownership</option>
                                        <option value="Others">Others</option>
                                    </select>
                                    {formikUploadDoc.touched.docType &&
                                        formikUploadDoc.errors.docType ? (
                                        <p className="error_text">
                                            {formikUploadDoc.errors.docType}
                                        </p>
                                    ) : null}
                                </div>
                                <Modal.Footer>
                                    <button variant="secondary" onClick={handleClose} type='button' className='dangerbutton'>
                                        Close
                                    </button>
                                    <button type='submit' variant="primary" className='addbutton' >
                                        Save 
                                    </button>
                                </Modal.Footer>
                            </form>
                        </Modal.Body>
                    </div>
                </Modal>
            </div>

        <div>
            <Modal
            show={confirmation}
            onHide={() => setConfirmation(!confirmation)}
            className="addnewpopup"
            backdrop="static"
            >
            <Modal.Header closeButton>
                {/* <Modal.Title>Select Document Type</Modal.Title> */}
                <h4 className="heading24_bold">Confirmation</h4>
                {/* <div className="DisplayFlex flexWrap_wrap AlignItem_center mobilegap_12">
                <button className="emptyBtn">
                    <img src="/images/modal_closeIcon.svg" />
                </button>
                </div> */}
            </Modal.Header>
            <div className="modelScroll">
                <Modal.Body>
                <span>Are You Sure, You Want To Delete This Document ?</span>
                </Modal.Body>
            </div>
            <Modal.Footer>
                <button variant="secondary" onClick={() => documentDelete()} className='dangerbutton'>
                Delete
                </button>
            </Modal.Footer>
            </Modal>
        </div>

        </>
    )
}

export default UploadDoc
