import React, { useState } from "react";
import { useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { AuthGet, AuthPost, Get, Post } from "../../common_var/httpService";
import Button from "../../Components/Button/Button";
import Dropdown from "../../Components/Dropdown/Dropdown";
import Number from "../../Components/Number/Number";
import Text from "../../Components/Text/Text";
import Toast from "../../Components/Toaster/Toast";
import { useStateValue } from "../../Context/StateProvider";
import { toast } from "react-hot-toast";

function DealInfo() {

  const route = useLocation().pathname;
  const reuserData = JSON.parse(sessionStorage.getItem("resuser"));
  console.log('dealcondition', route.includes('dealerinfopending') && reuserData.role == 4 ? true : false, 'no', route.includes('dealerinfopending'), 'yes', reuserData.role == 4)
  const [{ formData, errors, formSubmitted, socket }, dispatch] = useStateValue();
  const [salesTaxPopup, setSalesTaxPopup] = useState(false);
  const [tradeInPopup, setTradeInPopup] = useState(false);
  const [forDealer, setDealer] = useState(route.includes('dealerinfopending') && reuserData.role == 4 ? false : true)
  const queryParams = useLocation();
  let params = queryParams.pathname.split("/");
  const loanid = params[params.length - 1];
  const navigate = useNavigate()
  const f = formData;


  const getDetails = async () => {
    try {
      // let loanId=loanid
      let res = await AuthGet(`loan/dealinfo/${loanid}`, 'admin');
      if (res.statusCode === 200) {
        console.warn('kk', res.data[0])
        dispatch({
          type: "SET_VALUES",
          payload: res.data[0],
        });
        dispatch({
          type: "CLEAR_ERRORS",
        });
      }
    } catch (error) { }
  };

  useEffect(() => {
    dispatch({
      type: "CLEAR_ERRORS",
    });
    getDetails();



  }, []);

  const handleChange = (e) => {
    let typ = e.target.type;
    const name = e.target.name;
    let val =
      typ === "number"
        ? e.target.value == ""
          ? 0
          : 0 + parseInt(e.target.value)
        : e.target.value;

    dispatch({
      type: "SET_VALUES",
      payload: { [name]: val },
    });
  };

  const handleSubmit = async (e) => {
    // console.log(formData.terms)
    e.preventDefault();
    dispatch({
      type: "FORM_SUBMIT",
      payload: true,
    });
    try {
      formData.terms = +formData.terms;
      const bodyData = {
        ...formData,
        loan_id: loanid,
        dealincome: [],
      };
      console.warn(bodyData)
      if ( Object.keys(errors).length === 0) {
        const resp = await Post("loan/dealinfo", bodyData, 'admin');
        if (resp.statusCode == 200) {
          sendNotification("DealInformation", "");
          dispatch({
            type: "CLEAR_VALUES",
          });
          navigate('/admin/pending')
        }
      }
    } catch (error) { }
  };

  const sendNotification = async (stage, body) => {
    let bodyData = {
      ...body,
      stage: stage,
      loan_id: loanid,
      user_id: "",
    };
    console.log("bodyData", bodyData);
    await AuthPost(`socket/saveNotification`, bodyData, "admin")
      .then((res) => {
        if (res["statusCode"] == 200) {
          if (res["data"].length > 0) {
            for (var user of res["data"]) {
              // console.log('sssssssss.........................', {
              //     receiver_user_id: user.user_id,
              //     notification: { id: user.user_id, 'message': user.content },
              // })
              socket?.emit("sendNotification", {
                receiver_user_id: user?.user_id,
                notification: { id: user?.user_id, message: user?.content },
              });
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
      });
  };

  const terms_data = [
    { key: "12", value: "12" },
    { key: "24", value: "24" },
    { key: "36", value: "36" },
    { key: "48", value: "48" },
    { key: "60", value: "60" },
  ];

  const CalculateDealInfo = () => {
    let e = (f.purchase_price * f.sales_tax_per) / 100;
    f.sales_tax = e;

    let a = 0;
    a = a + f.purchase_price;
    a = a + f.sales_tax;
    a = a + f.license_registration;
    a = a + f.doc_fees;
    a = a + f.sum;

    let b = 0;
    b = b + f.cash_downpayment;

    let c = f.trade_in_value - f.trade_in_pay_off;
    f.trade_in = c;
    b = b + c;
    f.total_down = b;

    // for (let i = 0; i < l1.length; i++) {

    //   a = a + Number(l1[i]['amount']);

    // }

    a = a + f.rebate;

    a = a + f.other_frontend_fees;

    let k = a - b;
    f.frontend_total = k;

    let doc_stamps = 0.0035 * k;

    let z = k + doc_stamps;
    f.doc_stamps = doc_stamps;

    let adjustment_quotient = 0;
    f.adjustment_quotient = adjustment_quotient;

    let originationfee = +(
      495 +
      50 * ((k - 10000) / 2500) +
      adjustment_quotient
    );

    z = z + originationfee;
    f.originationfee = originationfee;

    let gap = +(495 + 100 * ((k - 15000) / 5000));
    f.gap = gap;
    z = z + gap + 195;
    f.total_loan_amount = z;

    let principal = f.total_loan_amount;
    let interest = 18 / 100 / 12;

    let payments = f.terms;

    let x = Math.pow(1 + interest, payments);

    if (x > 1) {
      let monthly = (principal * x * interest) / (x - 1);
      f.monthly = +monthly.toFixed(2);
    }

    f.realapr = +RealAPR(
      f.total_loan_amount,
      f.monthly,
      f.terms,
      18,
      (f.total_loan_amount - f.frontend_total).toFixed(2)
    ).toFixed(2);
  };

  const RealAPR = (loanAmount, monthlyPayment = 0, term, apr, originFess) => {
    let rate_per_period = apr / 100 / 12; //0.015

    let interest = rate_per_period;

    let futureValue = 0;

    let dueEndOrBeginning = 0;

    let paymentsPerYear = term;

    let paymentAmount = -monthlyPayment;

    let presentValue = loanAmount - originFess;

    let FINANCIAL_MAX_ITERATIONS = 128; //Bet accuracy with 128

    let FINANCIAL_PRECISION = 0.0000001; //1.0e-8

    let y,
      y0,
      y1,
      x0,
      x1 = 0,
      m = 0,
      i = 0;

    let rate = interest;

    if (Math.abs(rate) < FINANCIAL_PRECISION) {
      y =
        presentValue * (1 + paymentsPerYear * rate) +
        paymentAmount * (1 + rate * dueEndOrBeginning) * paymentsPerYear +
        futureValue;
    } else {
      m = Math.exp(paymentsPerYear * Math.log(1 + rate));

      y =
        presentValue * m +
        paymentAmount * (1 / rate + dueEndOrBeginning) * (m - 1) +
        futureValue;
    }

    y0 = presentValue + paymentAmount * paymentsPerYear + futureValue;

    y1 =
      presentValue * m +
      paymentAmount * (1 / rate + dueEndOrBeginning) * (m - 1) +
      futureValue;

    // find root by Newton secant method

    i = x0 = 0.0;

    x1 = rate;

    while (
      Math.abs(y0 - y1) > FINANCIAL_PRECISION &&
      i < FINANCIAL_MAX_ITERATIONS
    ) {
      rate = (y1 * x0 - y0 * x1) / (y1 - y0);

      x0 = x1;

      x1 = rate;

      if (Math.abs(rate) < FINANCIAL_PRECISION) {
        y =
          presentValue * (1 + paymentsPerYear * rate) +
          paymentAmount * (1 + rate * dueEndOrBeginning) * paymentsPerYear +
          futureValue;
      } else {
        m = Math.exp(paymentsPerYear * Math.log(1 + rate));

        y =
          presentValue * m +
          paymentAmount * (1 / rate + dueEndOrBeginning) * (m - 1) +
          futureValue;
      }

      y0 = y1;

      y1 = y;

      ++i;
    }

    return rate * 100 * 12;
  };

  useEffect(() => {
    dispatch({
      type: "SET_VALUES",
      payload: {
        purchase_price: 0,
        sales_tax: 0,
        sales_tax_per: 0,
        license_registration: 0,
        doc_fees: 0,
        cash_downpayment: 0,
        trade_in: 0,
        trade_in_value: 0,
        trade_in_pay_off: 0,
        total_down: 0,
        other_frontend_fees: 0,
        frontend_total: 0,
        terms: "",
        title: "",
        sum: 0,
        rebate: 0,
        open: 0,
        gps: 195,
        monthly: 0,
      },
    });
  }, []);

  useEffect(() => {
    CalculateDealInfo();
  }, [
    f.purchase_price,
    f.sales_tax,
    f.license_registration,
    f.doc_fees,
    f.other_frontend_fees,
    f.sum,
    f.cash_downpayment,
    f.trade_in,
    f.trade_in_value,
    f.trade_in_pay_off,
    f.sales_tax_per,
  ]);
  return (
    <div>
      <Toast />
      <form className="row">
        <Number
          name={"purchase_price"}
          labelName={"Purchase Price ($)"}
          placeholder={"Purchase Price"}
          handleChange={handleChange}
          required={!forDealer}
          readOnly={forDealer}
        />
        <Number
          handleClick={() => setSalesTaxPopup(true)}
          name={"sales_tax"}
          labelName={"Sales Tax ($)"}
          placeholder={"Sales Tax"}
          handleChange={handleChange}
          required={!forDealer}
          readOnly={true}//calculated
        />
        {salesTaxPopup && (
          <div className="mb_30">
            <h3 className="heading20 mb_12">Sales Percentage</h3>
            <Number
              name={"sales_tax_per"}
              labelName={"Enter Percentage (%)"}
              placeholder={"%"}
              handleChange={handleChange}
              required={!forDealer}
              readOnly={forDealer}
            />
            <Button
              type={"button"}
              value={"close"}
              handleClick={() => setSalesTaxPopup(false)}
            />
          </div>
        )}
        <Number
          name={"license_registration"}
          labelName={"License/Registration ($)"}
          placeholder={"License/Registration"}
          handleChange={handleChange}
          required={!forDealer}
          readOnly={forDealer}
        />
        <Number
          name={"doc_fees"}
          labelName={"Doc Fees ($)"}
          placeholder={"Doc Fees"}
          handleChange={handleChange}
          required={!forDealer}
          readOnly={forDealer}
        />
        <Number
          name={"cash_downpayment"}
          labelName={"Cash down payment ($)"}
          placeholder={"Cash down payment"}
          handleChange={handleChange}
          required={!forDealer}
          readOnly={forDealer}
        />
        <Number
          handleClick={() => setTradeInPopup(true)}
          name={"trade_in"}
          labelName={"Trade-in ($)"}
          placeholder={"Trade-in"}
          handleChange={handleChange}
          readOnly={true}//calculated
        />
        {tradeInPopup && (
          <div className="mb_24">
            <h3 className="heading20 mb_12">Trade In</h3>
            <div className="row">
              {/* <div className="col-md-6"> */}
              <Number
                name={"trade_in_value"}
                labelName={"Trade-in Value ($)"}
                placeholder={"Trade-In value"}
                handleChange={handleChange}
                readOnly={forDealer}
              />
              {/* </div> */}
              {/* <div className="col-md-6"> */}
              <Number
                name={"trade_in_pay_off"}
                labelName={"Trade-in Payoff ($)"}
                placeholder={"Trade-In payoff"}
                handleChange={handleChange}
                readOnly={forDealer}
              />
              {/* </div> */}
            </div>
            <Button
              type={"button"}
              value={"close"}
              handleClick={() => setTradeInPopup(false)}
            />
          </div>
        )}
        <Number
          name={"total_down"}
          labelName={"Total down ($)"}
          placeholder={"Total down"}
          handleChange={handleChange}
          readOnly={forDealer}
        />
        <Number
          name={"other_frontend_fees"}
          labelName={"Other frontend Fees ($)"}
          placeholder={"Other frontend Fees"}
          handleChange={handleChange}
          readOnly={forDealer}
        />
        <Number
          name={"frontend_total"}
          labelName={"Frontend Total ($)"}
          placeholder={"Frontend Total"}
          handleChange={handleChange}
          required={!forDealer}
          readOnly={true}//calculated
        />
        <Dropdown
          data={terms_data}
          name={"terms"}
          labelName={"Terms"}
          handleChange={handleChange}
          required={!forDealer}
          readOnly={forDealer}
        />
        <Text
          name={"title"}
          labelName={"Title"}
          placeholder={"Title"}
          handleChange={handleChange}
          readOnly={forDealer}
          required={!forDealer}
          maxLen={100}
        />
        <Number
          name={"sum"}
          labelName={"Sum ($)"}
          placeholder={"Sum"}
          handleChange={handleChange}
          required={!forDealer}
          readOnly={forDealer}
        />
        {!forDealer ? <Button value={"Continue"} handleClick={handleSubmit} /> : <></>}
      </form>
    </div>
  );

}

export default DealInfo