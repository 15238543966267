import React, { useEffect, useState } from "react";
import { Get } from "../../common_var/httpService";
import bannermain from "../../assets/images/autobanner.png";
import bubble from "../../assets/images/bubble.svg";
import telephone from "../../assets/images/telephone.svg";
import quote from "../../assets/images/quote.svg";
import listicon from "../../assets/images/tick.svg";
import howitworks from "../../assets/images/howitworks.png";
import benefits from "../../assets/images/benfitss.png";
import check from "../../assets/images/check_circle.svg";
// import footerlogo from "../../assets/images/footer-logo.svg";
import "./landing.css";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../Components/Button/Button";
import StartPage from "./StartPage";
import { useStateValue } from "../../Context/StateProvider";
import Brandlogo from "../../assets/images/brand_logo.svg";
import dasharrow from "../../assets/images/dasharrow.svg";
import samplelogo from "../../assets/images/samplelogo.svg";
import readmorearrow from "../../assets/images/readmore_arrow.svg";
import dashtick from "../../assets/images/dash_tick.svg";

function Landing() {
  const queryParams = useLocation();
  const [{ currency }, dispatch] = useStateValue();
  const [loanPopup, setLoanPopup] = useState(false);
  const navigate = useNavigate();
  const [mobilemenu, setMobilemenu] = useState(false);
  let providername = queryParams.pathname?.split("/");

  const handleClick = () => {
    // setLoanPopup(true);
    dispatch({
      type: "SET_LOANAMOUNT_POPUP",
      payload: true,
    });
    // navigate("/select-loan");
    if (providername[1]) {
      window.open(`/select-loan/${providername[1]}`);
    } else {
      window.open("/select-loan", "_blank");
    }
  };
  const handleMobileClick = () => {
    setMobilemenu((current) => !current);
  };

  const handleLogin = () => {
    navigate("/borrower/login");
  };

  return (
    <div className="landingPage">
      {loanPopup && <StartPage />}
      <nav class="navbar navbar-expand-lg navbar-light logoheader plr40">
        <div class="container-fluid">
          <a class="navbar-brand brand_logo" href="#">
            <img src={Brandlogo} className="whiteFilter" />
          </a>
          {/* <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
  <span class="navbar-toggler-icon"></span>
</button> */}

          <button
            onClick={handleMobileClick}
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div
            class={
              mobilemenu ? "collapse navbar-collapse " : "show navbar-collapse"
            }
            id="navbarSupportedContent"
          >
            <div>
              <ul class="navbar-nav me-auto mb-2 mb-lg-0 pr100">
                <li class="nav-item">
                  <a class="nav-link active" aria-current="page" href="#home">
                    Home
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#howitwork">
                    How it works
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#benefit">
                    Benefits
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#contact">
                    Contact us
                  </a>
                </li>
              </ul>
            </div>

            <div className="d-flex">
              <div className="applybutton cursorpointer" onClick={handleClick}>
                Start Now
              </div>
              <div className="applybutton2 cursorpointer" onClick={handleLogin}>
                Login
              </div>
            </div>

            {/* <Button className="applybutton" type="button" value={"Apply Now"} handleClick={handleClick} />
          <Button className="applybutton" type="button" value={"Login"} handleClick={navigate('/borrower/login')} /> */}
            {/* </div> */}
          </div>
        </div>
      </nav>
      <div className="container-fluid " id="home">
        <div className="row pt78">
          <div className="col-md-6 bannerimg plr0">
            <img src={bannermain} />
          </div>
          <div className="col-md-6 bannercontentsection pr100">
            <div className="bannertitlefont">
              <span className="grey_text">Enabling</span> Affordable & Instant
              Auto Loans
            </div>
            <div className="pb30 bannerpara">
              A smarter and cost effective loan to make your medical treatments
              independent of bargains. Our functionally scalable solutions
              achieves your financial needs effectively.
            </div>
            <Button
              className="bg_grey  "
              type="button"
              value={"Start Now"}
              handleClick={handleClick}
            />
          </div>
        </div>
      </div>

      <section className="pt80">
        <div className="container">
          <div className="row alignItemcenter">
            <div className="col-md-6 clientsection">
              <h3 className="pb30">
                All Over India <span className="text_red">50+</span> Customers
                using our applications
              </h3>
              <div className="dasharrow">
                <img src={dasharrow} alt="" />
              </div>
            </div>

            <div className="col-md-6 containerr blur bannercontentsection ">
              <div className="row gap40 slider">
                <div className="col-md-5">
                  <img src={samplelogo} alt="" />
                </div>
                <div className="col-md-5">
                  <img src={samplelogo} alt="" />
                </div>
                <div className="col-md-5">
                  <img src={samplelogo} alt="" />
                </div>
                <div className="col-md-5">
                  <img src={samplelogo} alt="" />
                </div>
                <div className="col-md-5">
                  <img src={samplelogo} alt="" />
                </div>
                <div className="col-md-5">
                  <img src={samplelogo} alt="" />
                </div>
                <div className="col-md-5">
                  <img src={samplelogo} alt="" />
                </div>
                <div className="col-md-5">
                  <img src={samplelogo} alt="" />
                </div>
                <div className="col-md-5">
                  <img src={samplelogo} alt="" />
                </div>
                <div className="col-md-5">
                  <img src={samplelogo} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pt80 " id="howitwork">
        <div className="container-fluid">
          <div className="row columnreverse_mob">
            <div className="col-md-6 bannercontentsection pl150">
              <p className="sectionsubtitle pb10"> How it Works?</p>

              <p className="bannerpara pb30">
                We have simplified the process to empower you to avail Auto
                loans quickly.using Lorem Ipsum is that it has a more-or-less
                normal distribution of letters, as opposed to using 'Content
                here, content here', making it look like readable English. Many
                desktop publishing packages and web page editors now use Lorem
                Ipsum as their default model text, and a search for 'lorem
                ipsum' will uncover many web sites still in their infancy using
                Lorem Ipsum is that it has a more-or-less normal distribution of
                letters, as opposed to using 'Content here, content here',
                making it look like readable English.
              </p>

              <button className="greyborderbtn">
                <div>Read more</div>
                <div>
                  <img src={readmorearrow} alt="" />
                </div>
              </button>
            </div>
            <div className="col-md-6 plr0 ">
              <img className="w100per" src={howitworks} alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className="pt80" id="benefit">
        <div className="container-fluid ">
          <div className="row alignItemcenter">
            <div className="col-md-6 plr0">
              <img className="w600" src={benefits} />
            </div>
            <div className="col-md-6  ">
              <div>
                <p className="sectionsubtitle   pb10 ">Benefits</p>
                <p className="bannerpara pt15  ">
                  <span className="mr10">
                    <img src={dashtick} alt="" />
                  </span>
                  when an unknown printer took a galley of type and scrambled it
                  to type
                </p>
                <p className="bannerpara pt15 ">
                  <span className="mr10">
                    <img src={dashtick} alt="" />
                  </span>
                  when an unknown printer took a galley of type and scrambled it
                  to type
                </p>
                <p className="bannerpara pt15  pb30">
                  <span className="mr10">
                    <img src={dashtick} alt="" />
                  </span>
                  when an unknown printer took a galley of type and scrambled it
                  to type
                </p>
              </div>
              <div className="alignItemcenter displayFlex">
                <button className="greybgbtn mr30">Check Eligibility</button>
                <button className="greyborderbtn">
                  <div>Read more</div>
                  <div>
                    <img src={readmorearrow} alt="" />
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mt80 mb50 contactbg " id="contact">
        <div className="container ptb60">
          <div className="row ">
            <div className="col-md-12  p0_mob">
              <div className="pb15 sectionsubtitle  textaligncenter ">
                Contact Us
              </div>
              <div className="contacttext">
                As we envision the future in light of the challenges and
                opportunities of the present, we see the need As we envision the
                future in light of the challenges and opportunities of the
                present, we see the need .
              </div>
            </div>
          </div>

          <div className="row flexcolumnmob ">
            <div className="col-md-6 w100mob contactsection">
              <div className="whiteframe ">
                <div>
                  <div className="contacttitle ">Call Us Now</div>
                  <div className="contactcontect">
                    Need help with your Login? No problem! <br /> Our team is
                    here to help you. <br /> 508.475.9013
                  </div>
                  <div className="divcenter">
                    <button className="redbtn">
                      <div>Call Now</div>
                      <div>
                        <img src={readmorearrow} alt="" />
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 w100mob">
              <div className="whiteframe bordergrey">
                <div>
                  <div className="contacttitle">Request a Quote</div>
                  <div className="contactcontect">
                    If you prefer to communicate via email, please click below.
                    Please feel free to ask any questions may you have, we would
                    love to hear from you.
                  </div>
                  <div className="divcenter">
                    <button className="greybtn">
                      <div>Lets Talk</div>
                      <div>
                        <img src={readmorearrow} alt="" />
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pt80  footer_bg">
        <div className="container">
          <div className="row ">
            <div className="col-md-12 pb30 ">
              <div className="brand_logo">
                <img src={Brandlogo} className="whiteFilter" />
              </div>
            </div>
            <div className="row">
              <div className="col-md-2">
                <div className="footerlist">
                  <ul className="">
                    <li>
                      <a href="#home">
                        <span style={{ color: "#ffffff" }}>Home</span>
                      </a>
                    </li>
                    <li>
                      <a href="#howitwork">
                        <span style={{ color: "#ffffff" }}>How it works?</span>
                      </a>
                    </li>
                    <li>
                      <a href="#benefit">
                        <span style={{ color: "#ffffff" }}>Benefits</span>
                      </a>
                    </li>
                    <li>
                      <a href="#contact">
                        <span style={{ color: "#ffffff" }}>Contact us</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-5">
                <p className=" footertext">
                  As we envision the future in light of the challenges and
                  opportunities of the present, we see the need As we envision
                  the future in light of the challenges and opportunities of the
                  present, we see the need the printing and typesetting
                  industry. Lorem Ipsum has been the industry's standard
                </p>
              </div>
              <div className="col-md-5">
                <div className="footertext">
                  <h5>Why do we use it</h5>
                  <p>
                    text of the printing and typesetting industry. Lorem Ipsum
                    has been the industry's standard dummy text ever since the
                    1500s, when an unknown printer took a galley of type and
                    scrambled
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="footer_bg">
        <div className="footerline "></div>
        <div className="container">
          <div className="row fs12 pb15">
            <div className="col-md-6 pb15">
              <div>
                Copyright © 2020-2023 Theecode Technologies Private Limited. All
                Rights Reserved.
              </div>
            </div>
            <div className="col-md-6 displayFlex justifyend pb15">
              <div className="footerbottomlist">
                <ul className="displayFlex alignItemcenter">
                  <li>
                    <a href="#">
                      {" "}
                      <span style={{ color: "#5A7072" }}>
                        Terms & Conditions
                      </span>
                    </a>
                  </li>
                  <li>|</li>
                  <li>
                    <a href="#">
                      <span style={{ color: "#5A7072" }}>Privacy Policy</span>
                    </a>
                  </li>
                  <li>|</li>
                  <li>
                    <a href="#">
                      {" "}
                      <span style={{ color: "#5A7072" }}>Accessibility</span>
                    </a>
                  </li>
                  <li>|</li>
                  <li>
                    <a href="#">
                      <span style={{ color: "#5A7072" }}>Legal</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Landing;
