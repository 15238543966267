import React, { useEffect, useState } from "react";
import { AuthGet, AuthPost, GooglePlaces } from "../../common_var/httpService";
import { useLocation, useNavigate} from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import Modal from "react-bootstrap/Modal";
import updateIcon from "../../assets/images/updateIcon.svg";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  EmailRegex,
  url,
  zipcode,
} from "../../Components/Regex/Regex";
import toast from "react-hot-toast";
import Toast from "../../Components/Toaster/Toast";
import {
  contactNameValidation,
  emailValidation,
  practiseValidation,
  stateValidation,
  updatePracticeUrl,
  providernameValidation,
} from "../CommonFunctions/Validations";
import { isValidPhoneNumber } from "react-phone-number-input";
import AddressAutocomplete from "../../Components/Address/Address";
import closeButton from "../../assets/images/close-icon.svg"
import PhoneNumInput from "../../Components/PhoneNumInput/PhoneNumInput";

//just check the file
function PracticeManagement() {
  const [file, setFiles] = useState("");
  const [layoutsData, setLayoutsData] = useState([]);
  const [editfile, setEditfile] = useState("");
  const [subscriptionId, setSubscriptionId] = useState(null);
  const domain = window.location.href.split("/admin")[0];
  const [defaultLayout, setDefaultLayout] = useState("");
  const [pricingModel, SetPricingModel] = useState([]);
  const [version, setVersion] = useState([]);
  const [defaultPricingModal, setDefaultpricingModal] = useState("");
  const [dVersion, setDVersion] = useState([]);
  const [location, setLocation] = useState("");
  const tabs = JSON.parse(sessionStorage.getItem("tabs"));
  const queryParams = useLocation();
  const loanParams = queryParams.pathname.slice(7);

  const handleCloses = () => {
    handleClose();
    setEditfile("");
  };

  const getSubscriptionId = async () => {
    const user_id = sessionStorage.getItem("UserId");
    let res = await AuthGet(`api/fetchsid/${user_id}`, "admin");
    setSubscriptionId(res.data[0].subscription_id);
  };
  useEffect(() => {
    formik.setFieldValue("layout_id", defaultLayout);
    formik.setFieldValue("version", dVersion);
  }, [defaultLayout, dVersion]);

  const validationSchema = Yup.object({
    contactName: Yup.string()
      .required("*Contact Name Is Required")
      .min(1, "*Contact Name Must Be Between 1 and 50 Characters")
      .max(50, "*Contact Name Must Be Between 1 and 50 Characters"),

    practiceName: Yup.string()
      .required("*Dealer Name Is Required")
      .min(1, "*Dealer Name Must Be Between 1 and 50 Characters")
      .max(50, "*Dealer Name Must Be Between 1 and 50 Characters"),
    // .matches(onlyAlphabet,
    //   '*Dealer Name Can Only Contain Alphabets.'
    // ),

    email: Yup.string()
      .required("*Mail Address Is Required")
      .matches(EmailRegex, "*Enter A Valid Email Address"),


      unit: Yup.string()
      .required("*Unit Is Required"),
    // practiceUrl: Yup.string()
    //   .required('*Dealer App Link Is Required')
    //   .matches(url, '*Enter A Valid Dealer App Link'),

    practiceHomeUrl: Yup.string()
      .required("*Dealer Website Is Required")
      .matches(url, "*Enter A Valid Dealer Website"),

    locationName: Yup.string()
      .required("*Address Is Required")
      .min(1, "*Address Must Be Between 1 and 200 Characters")
      .max(200, "*Address Must Be Between 1 and 200 Characters"),

    phoneNumber: Yup.string()
      .required("*Phone Number Is Required")
      .test("length", "Invalid Phone Number", (val) =>
        isValidPhoneNumber(val === undefined ? "" : val)
      ),

    // streetaddress: Yup.string()
    //   .required("*Street Address Is Required")
    //   .min(1, "*Street Address Must Be Between 1 and 200 Characters")
    //   .max(200, "*Street Address Must Be Between 1 and 200 Characters"),

    city: Yup.string()
      .required("*City Is Required")
      .min(1, "*City Must Be Between 1 and 50 Characters")
      .max(50, "*City Must Be Between 1 and 50 Characters"),

    stateCode: Yup.string().required("*State is required"),
    

    zipcode: Yup.string()
      .required("*Zipcode Is Required"),
      // .test(
      //   "length",
      //   "Must be exactly 5 characters",
      //   (val) => val?.toString().length === 5
      // ),

    // practiceSettings: Yup.string()
    //   .required('*Provider Settings is required'),

    layout_id: Yup.string().required("*Layout is required"),
    version_id: Yup.string().required("*Version is required"),
    pricing_id: Yup.string().required("*Pricing Model is required"),
  });

  const edittedAddress = async (e) => {
    setLocation(e?.value?.description);
    let Address_data = await GooglePlaces(e);
    formikedit.setFieldValue("locationName",Address_data.Address);
    formikedit.setFieldValue("city",Address_data.City);
    formikedit.setFieldValue("stateCode",Address_data.State);
    formikedit.setFieldValue("zipcode",Address_data.Zipcode);
  }

  const formik = useFormik({
    initialValues: {
      contactName: "",
      email: "",
      practiceName: "",
      practiceUrl: "",
      practiceHomeUrl: "",
      locationName: "",
      phoneNumber: "",
      // streetaddress: "",
      city: "",
      stateCode: "",
      zipcode: "",
      // practiceSettings: '',
      layout_id: defaultLayout,
      version_id: dVersion,
      pricing_id: "",
      unit:""
    },

    // enableReinitialize:true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // if(editfile==null||editfile==''){
      //   return;
      // }
      let version_name = version?.filter((e) => {
        if (e?.id === values?.version_id) {
          return e?.version;
        }
      });
      let pricing_model_name = pricingModel?.filter((e) => {
        if (e?.id === values?.pricing_id) {
          return e;
        }
      });

      let admin = JSON.parse(sessionStorage.getItem("resuser"));
      let sendData = {
        contactName: values.contactName,
        email: values.email,
        practiceName: values.practiceName,
        practiceUrl: updatePracticeUrl(values?.practiceName),
        practiceHomeUrl: values.practiceHomeUrl,
        practiceLinkToForm: "linktoForm",
        locationName: values.locationName,
        // streetaddress: values.streetaddress,
        city: values.city,
        stateCode: values.stateCode,
        zipcode: values.zipcode,
        phoneNumber: values.phoneNumber,
        // practiceSettings: values.practiceSettings,
        layout_id: values.layout_id,
        practicelogo: "",
        admin_id: admin.id,
        subscription_id: subscriptionId,
        version_id: values.version_id,
        pricing_id: values.pricing_id,
        version_name: version_name[0]?.version,
        pricing_model_name: pricing_model_name[0]?.pricing_model,
        unit:values.unit
      };

      await AuthPost(
        `provider-management/create-provider`,
        sendData,
        "admin"
      ).then((res) => {
        if (res?.statusCode === 200) {
          handleCloseEdit();
          handleClose();
          getPracticeList();
          formik.resetForm();
          toast.success("Dealer Is Added Successfully", { duration: 4000 });
        } else {
          toast.error(res.message, { duration: 4000 });
        }
      });
    },
  });

  const [editData, setEditData] = useState({
    contactName: "",
    email: "",
    practiceName: "",
    practiceUrl: "",
    practiceHomeUrl: "",
    practiceLinkToForm: "linktoForm",
    locationName: "",
    streetaddress: "",
    city: "",
    stateCode: "",
    zipcode: "",
    phoneNumber: "",
    // practiceSettings: '',
    practiceMainColor: "",
    pacticeSecondaryColor: "",
    practicelogo: "",
    practicepoweredbylogo: "",
    layout_id: "",
    pricing_id: "",
    version_id: "",
    unit:""
  });

  const formikedit = useFormik({
    initialValues: {
      contactName: editData?.contactName,
      email: editData?.email,
      practiceName: editData?.practiceName,
      practiceUrl: `${domain}/${editData?.practiceName}`,
      practiceHomeUrl: editData?.practiceHomeUrl,
      locationName: editData?.locationName,
      streetaddress: editData?.streetaddress,
      city: editData?.city,
      stateCode: editData?.stateCode,
      zipcode: editData?.zipcode,
      phoneNumber: editData?.phoneNumber,
      layout_id: editData?.layout_id,
      pricing_id: editData?.pricing_id,
      version_id: editData?.version_id,
      unit:editData?.unit
      // practiceSettings: editData?.practiceSettings,
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // if(file==null||file==''){
      //   return;
      // }
      let version_name = version?.filter((e) => {
        if (e?.id === values?.version_id) {
          return e?.version;
        }
      });
      let pricing_model_name = pricingModel?.filter((e) => {
        if (e?.id === values?.pricing_id) {
          return e;
        }
      });


      let admin = JSON.parse(sessionStorage.getItem("resuser"));
      let sendData = {
        contactName: values.contactName,
        email: values.email,
        practiceName: values.practiceName,
        practiceUrl: updatePracticeUrl(values?.practiceName),
        practiceHomeUrl: values.practiceHomeUrl,
        practiceLinkToForm: "linktoForm",
        locationName: values.locationName,
        streetaddress: values.streetaddress,
        city: values.city,
        stateCode: values.stateCode,
        zipcode: values.zipcode,
        phoneNumber: values.phoneNumber,
        // practiceSettings: values.practiceSettings,
        practicelogo: "",
        layout_id: values.layout_id,
        admin_id: admin.id,
        version_id: values?.version_id,
        pricing_id: values?.pricing_id,
        provider_id: editData?.provider_id,
        version_name: version_name[0]?.version,
        pricing_model_name: pricing_model_name[0]?.pricing_model,
        unit:values.unit
        // subscription_id: admin.id
      };
      await AuthPost(
        `provider-management/update-provider-info/${editData?.id}`,
        sendData,
        "admin"
      ).then((res) => {
        if (res?.statusCode === 200) {
          handleCloseEdit();
          handleClose();
          getPracticeList();
          toast.success("Dealer Is Updated Successfully", { duration: 4000 });
        } else {
          toast.error(res.message, { duration: 4000 });
        }
      });
    },
  });


  const navigate = useNavigate();

  const [loanData, setLoanData] = useState([]);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [form, setForm] = useState({
    contactName: "",
    email: "",
    practiceName: "",
    practiceUrl: "",
    practiceHomeUrl: "",
    practiceLinkToForm: "linktoForm",
    locationName: "",
    streetaddress: "",
    city: "",
    stateCode: "",
    zipcode: "",
    phoneNumber: "",
    // practiceSettings: '',
    practiceMainColor: "",
    pacticeSecondaryColor: "",
    practicelogo: "",
    practicepoweredbylogo: "",
  });
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    formik.resetForm();
  };
  const handleShow = () => {
    formik.setFieldValue("layout_id", defaultLayout);
    formik.setFieldValue("version_id", dVersion);
    formik.setFieldValue("pricing_id", defaultPricingModal);
    setShow(true);
  };
  const [showedit, setShowEdit] = useState(false);
  const handleCloseEdit = () => {
    setShowEdit(false);
    formikedit.resetForm();
  };
  const [rules, setRules] = useState([]);

  const [value, setValue] = useState();

  let stateList = [
    { id: "AL", displayName: "Alabama" },
    { id: "AK", displayName: "Alaska" },
    { id: "AZ", displayName: "Arizona" },
    { id: "CA", displayName: "California" },
    { id: "CO", displayName: "Colorado" },
    { id: "CT", displayName: "Connecticut" },
    { id: "DE", displayName: "Delaware" },
    { id: "GA", displayName: "Georgia" },
    { id: "HI", displayName: "Hawaii" },
    { id: "ID", displayName: "Idaho" },
    { id: "IL", displayName: "Illinois" },
    { id: "IN", displayName: "Indiana" },
    { id: "IA", displayName: "Iowa" },
    { id: "KS", displayName: "Kansas" },
    { id: "KY", displayName: "Kentucky" },
    { id: "LA", displayName: "Louisiana" },
    { id: "ME", displayName: "Maine" },
    { id: "MA", displayName: "Massachusetts" },
    { id: "MI", displayName: "Michigan" },
    { id: "MS", displayName: "Mississippi" },
    { id: "MO", displayName: "Missouri" },
    { id: "MN", displayName: "Minnesota" },
    { id: "MT", displayName: "Montana" },
    { id: "NE", displayName: "Nebraska" },
    { id: "NV", displayName: "Nevada" },
    { id: "NH", displayName: "New Hampshire" },
    { id: "NJ", displayName: "New Jersey" },
    { id: "NM", displayName: "New Mexico" },
    { id: "NY", displayName: "New York" },
    { id: "NC", displayName: "North Carolina" },
    { id: "ND", displayName: "North Dakota" },
    { id: "OH", displayName: "Ohio" },
    { id: "OK", displayName: "Oklahoma" },
    { id: "OR", displayName: "Oregon" },
    { id: "PA", displayName: "Pennsylvania" },
    { id: "SC", displayName: "South Carolina" },
    { id: "TN", displayName: "Tennessee" },
    { id: "TX", displayName: "Texas" },
    { id: "UT", displayName: "Utah" },
    { id: "VA", displayName: "Virginia" },
    { id: "WA", displayName: "Washington" },
    { id: "WV", displayName: "West Virginia" },
    { id: "WI", displayName: "Wisconsin" },
    { id: "WY", displayName: "Wyoming" },
  ];

  useEffect(() => {
    getSubscriptionId();
    getPracticeList();
    getPracticerules();
    getLayouts();
  }, []);

  const getLayouts = async () => {
    try {
      let resp = await AuthGet("api/layouts", "admin");
      if (resp?.statusCode === 200) {
        setLayoutsData(resp?.data?.layout);
        setVersion(resp?.data?.Version);
        SetPricingModel(resp?.data?.PricingModal);
        resp?.data?.layout?.filter((e) => {
          if (e.layout_key == "default") {
            setDefaultLayout(e.layout_id);
          }
        });
        resp?.data?.PricingModal?.filter((e) => {
          if (e.pricing_model_key == "default") {
            setDefaultpricingModal(e?.id);
          }
        });

        resp?.data?.Version?.filter((e) => {
          if (e.version_key == "default") {
            setDVersion(e.id);
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getPracticeList = async () => {
    await AuthGet(`provider-management`, "admin").then((res) => {
      // if (res?.statusCode === 200) {
        const enrichedLoanData = res?.allPractice.map((item, index) => ({
          
          ...item,
          serialNumber: index + 1,
        }));
        setLoanData(enrichedLoanData);
        setPage(Math.ceil(enrichedLoanData.length / 10));
        setCurrentPage(1);
      // }
    });
  };

  const getPracticerules = async () => {
    await AuthGet(`provider-rules/fetch-provider-rules`, "admin").then(
      (res) => {
        if (res?.statusCode === 200) {
          setRules(res?.data);
        }
      }
    );
  };

  const getEditPractice = async (id) => {
    await AuthGet(`provider-management/${id}`, "admin").then((res) => {
      if (res?.statusCode === 200) {
        let data = res?.practice;
        setLocation(data?.locationname)
        setEditData({
          contactName: data?.contactname,
          email: data?.email,
          practiceName: data?.practicename,
          practiceUrl: data?.practiceurl,
          practiceHomeUrl: data?.practicehomeurl,
          practiceLinkToForm: "linktoForm",
          locationName: data?.locationname,
          streetaddress: data?.streetaddress,
          city: data?.city,
          stateCode: data?.statecode,
          zipcode: data?.zipcode,
          phoneNumber: data?.phonenumber,
          // practiceSettings: data?.practicesettings,
          practiceMainColor: data?.practicemaincolor,
          pacticeSecondaryColor: data?.practicesecondarycolor,
          practicelogo: data?.practicelogo,
          practicepoweredbylogo: data?.practicepoweredbylogo,
          id: data?.id,
          layout_id: data?.layout_id,
          pricing_id: data?.pricing_id,
          version_id: data?.version_id,
          provider_id: data?.provider_id,
          unit:data?.unit,
        });
        setFiles(res?.practice?.practicelogo);
        setShowEdit(true);
      }
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleInputEdit = (e) => {
    const { name, value } = e.target;
    setEditData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e, method) => {
    e.preventDefault();

    if (method === "POST") {
      let sendData = {
        contactName: form?.contactName,
        email: form?.email,
        practiceName: form?.practiceName,
        practiceUrl: form?.practiceUrl,
        practiceHomeUrl: form?.practiceHomeUrl,
        practiceLinkToForm: "linktoForm",
        locationName: form?.locationName,
        streetaddress: form?.streetaddress,
        city: form?.city,
        stateCode: form?.stateCode,
        zipcode: form?.zipcode,
        phoneNumber: form?.phoneNumber,
        // practiceSettings: form?.practiceSettings,
        practiceMainColor: form?.practiceMainColor,
        pacticeSecondaryColor: form?.pacticeSecondaryColor,
        practicelogo: form?.practicelogo,
        practicepoweredbylogo: form?.practicepoweredbylogo,
      };
      await AuthPost(
        `provider-management/create-provider`,
        sendData,
        "admin"
      ).then((res) => {
        if (res?.statusCode === 200) {
          handleCloseEdit();
          handleClose();
          getPracticeList();
          setForm({
            contactName: "",
            email: "",
            practiceName: "",
            practiceUrl: "",
            practiceHomeUrl: "",
            practiceLinkToForm: "linktoForm",
            locationName: "",
            streetaddress: "",
            city: "",
            stateCode: "",
            zipcode: "",
            phoneNumber: "",
            // practiceSettings: '',
            practiceMainColor: "",
            pacticeSecondaryColor: "",
            practicelogo: "",
            practicepoweredbylogo: "",
          });
        }
      });
    } else if (method === "PUT") {
      let sendData = {
        contactName: editData?.contactName,
        email: editData?.email,
        practiceName: editData?.practiceName,
        practiceUrl: editData?.practiceUrl,
        practiceHomeUrl: editData?.practiceHomeUrl,
        practiceLinkToForm: "linktoForm",
        locationName: editData?.locationName,
        streetaddress: editData?.streetaddress,
        city: editData?.city,
        stateCode: editData?.stateCode,
        zipcode: editData?.zipcode,
        phoneNumber: editData?.phoneNumber,
        // practiceSettings: editData?.practiceSettings,
        practiceMainColor: editData?.practiceMaincolor,
        pacticeSecondaryColor: editData?.pacticeSecondaryColor,
        practicelogo: editData?.practicelogo,
        practicepoweredbylogo: editData?.practicepoweredbylogo,
      };
      await AuthPost(
        `provider-management/update-provider-info/${editData?.id}`,
        sendData,
        "admin"
      ).then((res) => {
        if (res?.statusCode === 200) {
          handleCloseEdit();
          handleClose();
          getPracticeList();
          setForm({
            contactName: "",
            email: "",
            practiceName: "",
            practiceUrl: "",
            practiceHomeUrl: "",
            practiceLinkToForm: "linktoForm",
            locationName: "",
            streetaddress: "",
            city: "",
            stateCode: "",
            zipcode: "",
            phoneNumber: "",
            // practiceSettings: '',
            practiceMainColor: "",
            pacticeSecondaryColor: "",
            practicelogo: "",
            practicepoweredbylogo: "",
          });
        }
      });
    }
  };

  const columns = [
    {
      dataField: 'serialNumber',
      text: "S.NO",
      sort: true,
      // formatter: (e, column, columnIndex, row, rowIndex) => {
      //   let total = currentPage * 5;
      //   total = total - 5;
      //   let slNo = total + columnIndex + 1;
      //   return slNo;
      // },
      headerStyle: {
        width: "50px",
      },
      style: {
        overflow: 'hidden',
        textAlign:'center'
      },
    },
    {
      dataField: "practicename",
      text: "Dealer Name",
      sort: true,
      headerStyle: {
        width: "150px",
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "contactname",
      text: "Business Contact",
      sort: true,
      headerStyle: {
        width: "170px",
      },
      style: {
        overflow: "hidden",
        overflowWrap: "break-word",
      },
    },
    {
      dataField: "email",
      text: "Business Email",
      sort: true,
      headerStyle: {
        width: "250px",
      },
      style: {
        overflow: "hidden",
        overflowWrap: "break-word",
      },
    },
    {
      dataField: "locationname",
      text: "Address",
      sort: true,
      headerStyle: {
        width: "150px",
      },
      style: {
        overflow: "hidden",
        overflowWrap: "break-word",
      },
    },
    {
      dataField: "city",
      text: "City",
      sort: true,
      headerStyle: {
        width: "100px",
      },
      style: {
        overflow: "hidden",
        overflowWrap: "break-word",
      },
    },
    {
      dataField: "statecode",
      text: "State",
      sort: true,
      headerStyle: {
        width: "100px",
      },
      style: {
        overflow: "hidden",
        overflowWrap: "break-word",
      },
    },
    {
      dataField: "unit",
      text: "Unit",
      sort: true,
      headerStyle: {
        width: "100px",
      },
      style: {
        overflow: "hidden",
        overflowWrap: "break-word",
      },
    },
    {
      dataField: "practiceurl",
      text: "Dealer App link",
      sort: true,
      headerStyle: {
        width: "200px",
      },
      style: {
        overflow: "hidden",
        overflowWrap: "break-word",
      },
    },
    {
      dataField: "",
      text: "Action",
      sort: true,
      formatter: () => {
        return (
          <button className="btn deleteIcon">
            <img src={updateIcon} />
          </button>
        );
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          getEditPractice(row?.id);
        },
      },
      headerStyle: {
        width: "100px",
      },
      style: {
        overflow: "hidden",
      },
    },
  ];

  const defaultSorted = [
    // {
    //   dataField: 'createdat',
    //   order: 'desc'
    // },
    // {
    //   dataField: 'practicename',
    //   order: 'desc'
    // },
    // {
    //   dataField: 'contactname',
    //   order: 'desc'
    // },
    // {
    //   dataField: 'email',
    //   order: 'desc'
    // },
    // {
    //   dataField: 'locationname',
    //   order: 'desc'
    // },
    // {
    //   dataField: 'city',
    //   order: 'desc'
    // },
    // {
    //   dataField: 'statecode',
    //   order: 'desc'
    // },
    // {
    //   dataField: 'practiceurl',
    //   order: 'desc'
    // }
  ];

  const pagination = paginationFactory({
    page: page,
    sizePerPage: 5,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      setCurrentPage(page);
    },
    onSizePerPageChange: function (page, sizePerPage) {
    },
  });

  const { SearchBar, ClearSearchButton } = Search;

  // const updatePracticeUrl = (val, data) => {
  //   if (val?.length > 0) {
  //     val = val?.toLowerCase();
  //     let str = "";
  //     for (let el of val) {
  //       if (el === " ") str += "-";
  //       else str += el;
  //     }
  //     return `${domain}/${str}`;
  //   }
  //   return domain;
  // };

  const handleAddress = async (e) => {
    let Address_data = await GooglePlaces(e);
    formik.setFieldValue("locationName", Address_data.Address);
    formik.setFieldValue("city", Address_data.City);
    formik.setFieldValue("stateCode", Address_data.State);
    formik.setFieldValue("zipcode", Address_data.Zipcode);
  };

  const edithandleAddress = async (e) => {
    let Address_data = await GooglePlaces(e);
    formikedit.setFieldValue("locationName", Address_data.Address);
    formikedit.setFieldValue("city", Address_data.City);
    formikedit.setFieldValue("stateCode", Address_data.State);
    formikedit.setFieldValue("zipcode", Address_data.Zipcode);
  };

  return (
    <div>
      <Toast />
      <div className="breadcrumbsection">
        <div>
          <div className="titleText "> Manage Dealer</div>
        </div>
        {tabs[loanParams].find((tab)=> tab.name === 'add_provider') && <button
          className="btn btn-primary"
          onClick={() => {
            handleShow();
            getLayouts();
            formik.values.layout_id = layoutsData[0]?.layout_id;
          }}
        >
          Add Dealer
        </button>}
      </div>

      <Modal show={show} className="addnewpopup">
        <Modal.Header>
          <div className="forclosebutton">
            <h4>Add New Dealer</h4>
          </div>
          <img src={closeButton} alt="close" onClick={handleClose} />
        </Modal.Header>
        <form>
          <div className="modelScroll">
            <Modal.Body>
              <section
                id="createApplicationFromBackend"
                className="start_application"
              >
                <form>
                  <div class="row">
                    <div class="form-group col-md-6 col-xs-12 col-sm-6">
                      <div class="float-label">
                        <label htmlFor="contactName">Business Contact <span className="requiredText">*</span> </label>
                        <input
                          placeholder="Business Contact"
                          formControlName="contactName"
                          id="contactName"
                          name="contactName"
                          class="adminInput"
                          onChange={(e) =>
                            formik.setFieldValue(
                              "contactName",
                              contactNameValidation(e.target.value)
                            )
                          }
                          onBlur={formik.handleBlur}
                          value={formik.values.contactName}
                        />
                      </div>
                      {formik.touched.contactName &&
                      formik.errors.contactName ? (
                        <p className="error_text text-danger">
                          {formik.errors.contactName}
                        </p>
                      ) : null}
                    </div>

                    <div class="form-group col-md-6 col-xs-12 col-sm-6">
                      <div class="float-label">
                        <label htmlFor="practiceHomeUrl">
                          Dealer Website <span className="requiredText">*</span>
                        </label>
                        <input
                          placeholder="Dealer Website"
                          formControlName="practiceHomeUrl"
                          id="practiceHomeUrl"
                          class="adminInput"
                          name="practiceHomeUrl"
                          onChange={(e) => {
                            formik.setFieldValue(
                              "practiceHomeUrl",
                              emailValidation(e.target.value)
                            );
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.practiceHomeUrl}
                        />
                      </div>
                      {formik.touched.practiceHomeUrl &&
                      formik.errors.practiceHomeUrl ? (
                        <p className="error_text text-danger">
                          {formik.errors.practiceHomeUrl}
                        </p>
                      ) : null}
                    </div>
                  </div>

                  <div class="row">
                    <div class="form-group col-md-6 col-xs-12 col-sm-6">
                      <div class="float-label">
                        <label htmlFor="practiceName">Dealer Name <span className="requiredText">*</span></label>
                        <input
                          placeholder="Dealer Name"
                          formControlName="practiceName"
                          id="practiceName"
                          class="adminInput"
                          name="practiceName"
                          onChange={(e) => {
                            formik.setFieldValue(
                              "practiceName",
                              providernameValidation(e.target.value)
                            );
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.practiceName}
                        />
                      </div>
                      {formik.touched.practiceName &&
                      formik.errors.practiceName ? (
                        <p className="error_text text-danger">
                          {formik.errors.practiceName}
                        </p>
                      ) : null}
                    </div>
                    <div class="form-group col-md-6 col-xs-12 col-sm-6">
                      <div class="float-label">
                        <label htmlFor="practiceUrl">Dealer App Link</label>
                        <input
                          placeholder="Dealer App Link"
                          formControlName="practiceUrl"
                          id="practiceUrl"
                          class="adminInput"
                          name="practiceUrl"
                          disabled
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={updatePracticeUrl(
                            formik?.values?.practiceName
                          )}
                        />
                      </div>
                      {formik.touched.practiceUrl &&
                      formik.errors.practiceUrl ? (
                        <p className="error_text text-danger">
                          {formik.errors.practiceUrl}
                        </p>
                      ) : null}
                    </div>
                  </div>

                 

                  <div class="row">
                    <div class="form-group col-sm-6 col-xs-12 col-sm-6">
                      <div class="float-label">
                        <label htmlFor="email">Dealer Email <span className="requiredText">*</span></label>
                        <input
                          placeholder="Dealer Email"
                          formControlName="email"
                          id="email"
                          name="email"
                          class="adminInput"
                          onChange={(e) => {
                            formik.setFieldValue(
                              "email",
                              emailValidation(e.target.value)
                            );
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                        />
                      </div>
                      {formik.touched.email && formik.errors.email ? (
                        <p className="error_text text-danger">
                          {formik.errors.email}
                        </p>
                      ) : null}
                    </div>

                    <div class="form-group col-md-6 col-xs-12 col-sm-6">
                      <div class="float-label">
                        <label htmlFor="mobileNumber">Phone Number <span className="requiredText">*</span></label>
                        <PhoneNumInput
                          className="mobileInput"
                          defaultCountry="US"
                          international
                          type="text"
                          formControlName="mobileNumber"
                          id="mobileNumber"
                          // mask="000-00-0000"
                          placeholder="Phone Number"
                          onChange={(e) => {
                            formik.setFieldValue("phoneNumber", e);
                          }}
                          name="phoneNumber"
                          onBlur={formik?.handleBlur}
                          value={formik?.values?.phoneNumber}
                        />
                      </div>

                      {formik?.touched?.phoneNumber &&
                      formik?.errors?.phoneNumber ? (
                        <p className="error_text text-danger">
                          {formik.errors.phoneNumber}
                        </p>
                      ) : null}
                    </div>
                  </div>

                  {/* <PhoneInput
                    placeholder="Enter phone number"
                    value={value}
                    onChange={(e) => { 
                      console.log("Event::", e);
                      setValue(value) }}/> */}

                  {/* <div class="row">
                    <div class="form-group col-xs-12 col-sm-6">
                      <div class="float-label">
                        <label htmlFor="streetaddress">Street</label>
                        <input
                          placeholder="Street"
                          formControlName="streetaddress"
                          class="adminInput"
                          id="streetaddress"
                          name="streetaddress"
                          onChange={(e) => {
                            formik.setFieldValue(
                              "streetaddress",
                              addressValidation(e.target.value, 200)
                            );
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.streetaddress}
                        />
                      </div>
                      {formik.touched.streetaddress &&
                      formik.errors.streetaddress ? (
                        <p className="error_text text-danger">
                          {formik.errors.streetaddress}
                        </p>
                      ) : null}
                    </div>
                  </div> */}
                  <div class="row">
                    {/* <div class="form-group col-md-6 col-xs-12 col-sm-6">
                      <div class="float-label">
                        <label htmlFor="locationName">Address</label>
                        <input
                          placeholder="Address"
                          formControlName="locationName"
                          id="locationName"
                          onkeydown="return /[a-z ]/i.test(event.key)"
                          class="adminInput"
                          name="locationName"
                          onChange={(e) => {
                            formik.setFieldValue(
                              "locationName",
                              addressValidation(e.target.value, 200)
                            );
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.locationName}
                        />
                      </div>
                      {formik.touched.locationName &&
                      formik.errors.locationName ? (
                        <p className="error_text text-danger">
                          {formik.errors.locationName}
                        </p>
                      ) : null}
                    </div> */}
                  <div className="addresscolumunset col-md-6">
                  <AddressAutocomplete
                      handleAddress={handleAddress}
                      required={true}
                      labelName={"Address"}
                      name="locationName"
                      // onChange={(e) => {
                      //   formik.setFieldValue(
                      //     "locationName",
                      //     addressValidation(e.target.value, 200)
                      //   );
                      // }}
                      onBlur={formik.handleBlur}
                      value={formik.values.locationName}
                    />
                    {!formik?.values?.locationName &&formik?.touched?.locationName &&
                    formik?.errors?.locationName ? (
                      <p className="error_text text-danger">
                        {formik?.errors?.locationName}
                      </p>
                    ) : null}
                  </div>

                    <div class="form-group col-md-6 col-xs-12 col-sm-6">
                      <div class="float-label">
                        <label htmlFor="city">City <span className="requiredText">*</span> </label>
                        <input
                          placeholder="City"
                          formControlName="city"
                          class="adminInput"
                          name="city"
                          id="city"
                          onChange={(e) => {
                            formik.setFieldValue(
                              "city",
                              stateValidation(e.target.value)
                            );
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.city}
                        />
                      </div>
                      {!formik?.values?.city &&formik?.touched?.city && formik?.errors?.city ? (
                        <p className="error_text text-danger">
                          {formik?.errors?.city}
                        </p>
                      ) : null}
                    </div>
                     <div class="form-group col-md-6 col-xs-12 col-sm-6">
                      <div class="float-label">
                        <label htmlFor="stateCode">State <span className="requiredText">*</span> </label>
                        <input
                          placeholder="State"
                          formControlName="stateCode"
                          class="adminInput"
                          name="stateCode"
                          id="stateCode"
                          onChange={(e) => {
                            formik.setFieldValue(
                              "stateCode",
                              stateValidation(e.target.value)
                            );  
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.stateCode}
                        />
                      </div>
                      {!formik?.values?.stateCode && formik?.touched?.stateCode && formik?.errors?.stateCode ? (
                        <p className="error_text text-danger">
                          {formik?.errors?.stateCode}
                        </p>
                      ) : null}
                    </div>
                    <div class="form-group col-md-6 col-xs-12 col-sm-6">
                      <div class="float-label">
                        <label htmlFor="zipcode">Zipcode <span className="requiredText">*</span></label>
                        <input
                          placeholder="Zipcode"
                          class="adminInput zipbox"
                          formControlName="zipcode"
                          name="zipcode"
                          id="zipcode"
                          onChange={(e) => {
                            formik.setFieldValue(
                              "zipcode",
                              zipcode(e.target.value)
                            );
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.zipcode?.replace(/[^\d]/g, "")}
                        />
                      </div>
                      {formik.touched.zipcode && formik.errors.zipcode ? (
                        <p className="error_text text-danger">
                          {formik.errors.zipcode}
                        </p>
                      ) : null}
                    </div>
                    <div class="form-group col-md-6 col-xs-12 col-sm-6">
                      <div class="float-label">
                        <label htmlFor="unit">Unit <span className="requiredText">*</span></label>
                        <input
                          placeholder="Unit"
                          formControlName="unit"
                          id="unit"
                          class="adminInput"
                          name="unit"
                          onChange={(e) => {
                            formik.setFieldValue(
                              "unit",
                              e.target.value
                            );
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.unit}
                        />

                      </div>

                      {formik?.touched?.unit &&
                      formik?.errors?.unit ? (
                        <p className="error_text text-danger">
                          {formik.errors.unit}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div class="row mb_24">
                    {/* <div class="form-group col-md-4 col-xs-12 col-sm-6">
                    <div class="float-label">
                    <label htmlFor="practiceSettings">Provider Settings</label>
                      <select
                        class="adminInput"
                        formControlName="practiceSettings"
                        name="practiceSettings"
                        id="practiceSettings"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.practiceSettings}
                      >
                        <option defaultValue value=''>
                          Provider Settings </option>
                        <option value="88">Surgery</option>
                        {rules?.map((data) => {
                          const { ref_no, setting_name } = data;
                          return (
                            <option value={ref_no}>{setting_name}</option>
                          )
                        })}
                      </select>
                    </div>
                    {formik.touched.practiceSettings && formik.errors.practiceSettings ? (
                      <p className="error_text text-danger">{formik.errors.practiceSettings}</p>
                    ) : null}
                  </div> */}
                    {/* <div class="form-group col-md-4 col-xs-12 col-sm-6">
                    <label>Provider Logo</label>

                    <input
                      type="file"
                    
                      class="file"
                      name="filename"
                      id="fileControl"
                      formControlName="practicelogo"
                     
                      onChange={((e)=>{setEditfile(e.target.value)})}
                     
                      accept="image/x-png,image/jpg,image/jpeg"
                      
                    />
                    
                  
                  </div> */}
                    <div class="form-group col-md-4 col-xs-12 col-sm-6">
                      <div class="float-label">
                        <label htmlFor="layout_id">Layouts</label>
                        <select
                          class="adminInput"
                          formControlName="layout_id"
                          name="layout_id"
                          id="layout_id"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik?.values?.layout_id}
                        >
                          {/* <option defaultValue value=''>
                          Layouts</option> */}
                          {layoutsData?.map((lay, i) =>
                            i == 0 ? (
                              <option
                                key={i}
                                value={lay?.layout_id}
                                selected
                                defaultValue
                              >
                                {lay?.layout_name}
                              </option>
                            ) : (
                              <option key={i} value={lay?.layout_id}>
                                {lay?.layout_name}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      {formik.touched.layout_id && formik.errors.layout_id ? (
                        <p className="error_text text-danger">
                          {formik.errors.layout_id}
                        </p>
                      ) : null}
                    </div>
                    <div class="form-group col-md-4 col-xs-12 col-sm-6">
                      <div class="float-label">
                        <label htmlFor="pricing_id">Pricing Model <span className="requiredText">*</span></label>
                        <select
                          class="adminInput"
                          formControlName="pricing_id"
                          name="pricing_id"
                          id="pricing_id"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik?.values?.pricing_id}
                        >
                          {pricingModel?.map((e, i) => (
                            <option key={i} value={e?.id}>
                              {e?.pricing_model}
                            </option>
                          ))}
                        </select>
                      </div>
                      {formik.touched.pricing_id && formik.errors.pricing_id ? (
                        <p className="error_text text-danger">
                          {formik.errors.pricing_id}
                        </p>
                      ) : null}
                    </div>
                    <div class="form-group col-md-4 col-xs-12 col-sm-6">
                      <div class="float-label">
                        <label htmlFor="layout_id">Pricing Model Version</label>
                        <select
                          class="adminInput"
                          formControlName="version_id"
                          name="version_id"
                          id="version_id"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik?.values?.version_id}
                        >
                          {version?.map((e, i) => (
                            <option key={i} value={e?.id}>
                              {e?.version}
                            </option>
                          ))}
                        </select>
                      </div>
                      {formik.touched.version_id && formik.errors.version_id ? (
                        <p className="error_text text-danger">
                          {formik.errors.version_id}
                        </p>
                      ) : null}
                    </div>
                  </div>
                </form>
              </section>
            </Modal.Body>
          </div>
        </form>
        <Modal.Footer>
          <div>
            <div>
              <button className="btn btn-secondary" onClick={handleCloses}>
                Cancel
              </button>
            </div>
          </div>
          <div>
            <button
              className="btn btn-primary"
              type="submit"
              onClick={formik.handleSubmit}
            >
              Add
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal show={showedit} onHide={handleCloseEdit} className="addnewpopup">
        <Modal.Header>
          <div>
            <h4>Update Dealer</h4>
          </div>
        </Modal.Header>
        <form>
          <div className="modelScroll">
            <Modal.Body>
              <section
                id="createApplicationFromBackend"
                className="start_application"
              >
                <form>
                  <div class="row">
                    <div class="form-group col-md-6 col-xs-12 col-sm-6">
                      <div class="float-label">
                        <label htmlFor="contactName">Business Contact</label>
                        <input
                          placeholder="Business Contact"
                          formControlName="contactName"
                          id="contactName"
                          class="adminInput"
                          name="contactName"
                          onChange={(e) =>
                            formikedit.setFieldValue(
                              "contactName",
                              contactNameValidation(e.target.value)
                            )
                          }
                          onBlur={formikedit.handleBlur}
                          value={formikedit.values.contactName}
                        />
                      </div>
                      {formikedit.touched.contactName &&
                      formikedit.errors.contactName ? (
                        <p className="error_text text-danger">
                          {formikedit.errors.contactName}
                        </p>
                      ) : null}
                    </div>

                    <div class="form-group col-md-6 col-xs-12 col-sm-6">
                      <div class="float-label">
                        <label htmlFor="practiceHomeUrl">
                        Dealer Website
                        </label>
                        <input
                          placeholder="Dealer Website"
                          formControlName="practiceHomeUrl"
                          id="practiceHomeUrl"
                          class="adminInput"
                          name="practiceHomeUrl"
                          onChange={(e) => {
                            formikedit.setFieldValue(
                              "practiceHomeUrl",
                              emailValidation(e.target.value)
                            );
                          }}
                          onBlur={formikedit.handleBlur}
                          value={formikedit.values.practiceHomeUrl}
                        />
                      </div>
                      {formikedit.touched.practiceHomeUrl &&
                      formikedit.errors.practiceHomeUrl ? (
                        <p className="error_text text-danger">
                          {formikedit.errors.practiceHomeUrl}
                        </p>
                      ) : null}
                    </div>
                  </div>

                  <div class="row">
                    <div class="form-group col-md-6 col-xs-12 col-sm-6">
                      <div class="float-label">
                        <label htmlFor="practiceName">Dealer Name</label>
                        <input
                          placeholder="Dealer Name"
                          formControlName="practiceName"
                          id="practiceName"
                          class="adminInput"
                          name="practiceName"
                          onChange={(e) => {
                            formikedit.setFieldValue(
                              "practiceName",
                              practiseValidation(e.target.value)
                            );
                          }}
                          onBlur={formikedit.handleBlur}
                          value={formikedit?.values?.practiceName}
                        />
                      </div>
                      {formikedit.touched.practiceName &&
                      formikedit.errors.practiceName ? (
                        <p className="error_text text-danger">
                          {formikedit.errors.practiceName}
                        </p>
                      ) : null}
                    </div>
                    <div class="form-group col-md-6 col-xs-12 col-sm-6">
                      <div class="float-label">
                        <label htmlFor="practiceUrl">Dealer App Link</label>
                        <input
                          placeholder="Dealer App Link"
                          formControlName="practiceUrl"
                          id="practiceUrl"
                          class="adminInput"
                          name="practiceUrl"
                          disabled
                          onChange={formikedit.handleChange}
                          onBlur={formikedit.handleBlur}
                          value={updatePracticeUrl(
                            formikedit?.values?.practiceName
                          )}
                        />
                      </div>
                      {formikedit.touched.practiceUrl &&
                      formikedit.errors.practiceUrl ? (
                        <p className="error_text text-danger">
                          {formikedit.errors.practiceUrl}
                        </p>
                      ) : null}
                    </div>
                  </div>

                  <div class="row">
                    <div class="form-group col-sm-6 col-xs-12 col-sm-6">
                      <div class="float-label">
                        <label htmlFor="email">Business Email</label>
                        <input
                          placeholder="Business Email"
                          formControlName="email"
                          class="adminInput"
                          name="email"
                          id="email"
                          onChange={(e) => {
                            formikedit.setFieldValue(
                              "email",
                              emailValidation(e.target.value)
                            );
                          }}
                          onBlur={formikedit.handleBlur}
                          value={formikedit?.values?.email}
                        />
                      </div>
                      {formikedit.touched.email && formikedit.errors.email ? (
                        <p className="error_text text-danger">
                          {formikedit.errors.email}
                        </p>
                      ) : null}
                    </div>

                    <div class="form-group col-md-6 col-xs-12 col-sm-6">
                      <div class="float-label">
                        <label htmlFor="phoneNumber">Phone Number</label>
                        <PhoneNumInput
                          className="mobileInput"
                          defaultCountry="US"
                          international
                          type="text"
                          name="phoneNumber"
                          formControlName="mobileNumber"
                          id="phoneNumber"
                          // mask="000-00-0000"
                          placeholder="Phone Number"
                          onChange={(e) => {
                            formikedit.setFieldValue("phoneNumber", e);
                          }}
                          onBlur={formikedit?.handleBlur}
                          value={formikedit?.values?.phoneNumber}
                        />
                        {/* <input
                          type="text"
                          name="phoneNumber"
                          formControlName="mobileNumber"
                          class="adminInput"
                          mask="000-00-0000"
                          placeholder="Phone Number"
                          id="phoneNumber"
                          onChange={(e) => {
                            formikedit.setFieldValue(
                              "phoneNumber",
                              mobile(e.target.value)
                            );
                          }}
                          onBlur={formikedit.handleBlur}
                          value={formikedit.values.phoneNumber}
                        /> */}
                      </div>
                      {/* {formikedit.touched.phoneNumber &&
                      formikedit.errors.phoneNumber ? (
                        <p className="error_text text-danger">
                          {formikedit.errors.phoneNumber}
                        </p>
                      ) : null} */}
                      {formikedit?.touched?.phoneNumber &&
                      formikedit?.errors?.phoneNumber ? (
                        <p className="error_text text-danger">
                          {formikedit.errors.phoneNumber}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div class="row">
                    {/* <div class="form-group col-md-6 col-xs-12 col-sm-6">
                      <div class="float-label">
                        <label htmlFor="locationName">Address</label>
                        <input
                          placeholder="Address"
                          formControlName="locationName"
                          id="locationName"
                          onkeydown="return /[a-z ]/i.test(event.key)"
                          class="adminInput"
                          name="locationName"
                          onChange={(e) => {
                            formikedit.setFieldValue(
                              "locationName",
                              addressValidation(e.target.value, 200)
                            );
                          }}
                          onBlur={formikedit.handleBlur}
                          value={formikedit.values.locationName}
                        />
                      </div>
                      {formikedit.touched.locationName &&
                      formikedit.errors.locationName ? (
                        <p className="error_text text-danger">
                          {formikedit.errors.locationName}
                        </p>
                      ) : null}
                    </div> */}
                     <AddressAutocomplete
                      handleAddress={edithandleAddress}
                      required={true}
                      labelName={"Address"}
                      name="locationName"
                      // onChange={(e) => {
                      //   formik.setFieldValue(
                      //     "locationName",
                      //     addressValidation(e.target.value, 200)
                      //   );
                      // }}
                      onBlur={formikedit.handleBlur}
                      value={formikedit.values.locationName}
                    />
                    {formikedit.touched.locationName &&
                    formikedit.errors.locationName ? (
                      <p className="error_text text-danger">
                        {formikedit.errors.locationName}
                      </p>
                    ) : null}

                    <div class="form-group col-md-6 col-xs-12 col-sm-6">
                      <div class="float-label">
                        <label htmlFor="city">City</label>
                        <input
                          placeholder="City"
                          formControlName="city"
                          class="adminInput"
                          id="city"
                          onChange={(e) => {
                            formikedit.setFieldValue(
                              "city",
                              stateValidation(e.target.value)
                            );
                          }}
                          onBlur={formikedit.handleBlur}
                          value={formikedit.values.city}
                          name="city"
                        />
                      </div>
                      {formikedit.touched.city && formikedit.errors.city ? (
                        <p className="error_text text-danger">
                          {formikedit.errors.city}
                        </p>
                      ) : null}
                    </div>

                    {/* <div class="form-group col-xs-12 col-sm-6">
                      <div class="float-label">
                        <label htmlFor="streetaddress">Street</label>
                        <input
                          placeholder="Street"
                          formControlName="streetaddress"
                          class="adminInput"
                          id="streetaddress"
                          onChange={(e) => {
                            formikedit.setFieldValue(
                              "streetaddress",
                              addressValidation(e.target.value, 200)
                            );
                          }}
                          onBlur={formikedit.handleBlur}
                          value={formikedit.values.streetaddress}
                          name="streetaddress"
                        />
                      </div>
                      {formikedit.touched.streetaddress &&
                      formikedit.errors.streetaddress ? (
                        <p className="error_text text-danger">
                          {formikedit.errors.streetaddress}
                        </p>
                      ) : null}
                    </div> */}
                  </div>

               
                  <div class="row">
                    <div class="form-group col-md-6 col-xs-12 col-sm-6">
                      <div class="float-label">
                        <label htmlFor="stateCode">State</label>
                        <input
                          placeholder="State"
                          formControlName="stateCode"
                          id="stateCode"
                          class="adminInput"
                          name="stateCode"
                          onChange={(e) => {
                            formikedit.setFieldValue(
                              "stateCode",
                              e.target.value
                            );
                          }}
                          onBlur={formikedit.handleBlur}
                          value={formikedit.values.stateCode}
                        />

                      </div>

                      {formikedit?.touched?.stateCode &&
                      formikedit?.errors?.stateCode ? (
                        <p className="error_text text-danger">
                          {formikedit.errors.stateCode}
                        </p>
                      ) : null}
                    </div>
                    <div class="form-group col-md-6 col-xs-12 col-sm-6">
                      <div class="float-label">
                        <label htmlFor="zipcode">Zipcode</label>
                        <input
                          placeholder="Zipcode"
                          class="adminInput zipbox"
                          formControlName="zipcode"
                          id="zipcode"
                          onChange={(e) => {
                            formikedit.setFieldValue(
                              "zipcode",
                              zipcode(e.target.value)
                            );
                          }}
                          onBlur={formikedit.handleBlur}
                          value={formikedit.values.zipcode?.replace(
                            /[^\d]/g,
                            ""
                          )}
                          name="zipcode"
                        />
                      </div>
                      {formikedit.touched.zipcode &&
                      formikedit.errors.zipcode ? (
                        <p className="error_text text-danger">
                          {formikedit.errors.zipcode}
                        </p>
                      ) : null}
                    </div>
                    <div class="form-group col-md-6 col-xs-12 col-sm-6">
                      <div class="float-label">
                        <label htmlFor="unit">Unit</label>
                        <input
                          placeholder="Unit"
                          formControlName="unit"
                          id="unit"
                          class="adminInput"
                          name="unit"
                          onChange={(e) => {
                            formikedit.setFieldValue(
                              "unit",
                              e.target.value
                            );
                          }}
                          onBlur={formikedit.handleBlur}
                          value={formikedit.values.unit}
                        />

                      </div>

                      {formikedit?.touched?.unit &&
                      formikedit?.errors?.unit ? (
                        <p className="error_text text-danger">
                          {formikedit.errors.unit}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div class="row mb_24">
                    {/* <div class="form-group col-md-4 col-xs-12 col-sm-6">
                    <div class="float-label">
                    <label htmlFor="practiceSettings">Provider Settings</label>
                      <select
                        class="adminInput"
                        formControlName="practiceSettings"
                        name="practiceSettings"
                        id="practiceSettings"
                        onChange={formikedit.handleChange}
                        onBlur={formikedit.handleBlur}
                        value={formikedit.values.practiceSettings}

                      >
                        <option defaultValue value=''>
                          Provider Settings </option>
                        <option value='88' selected>Surgery</option>
                        {rules?.map((data) => {
                          const { ref_no, setting_name } = data;
                          return (
                            <option value={ref_no}>{setting_name}</option>
                          )
                        })}
                      </select>
                    </div>
                    {formikedit.touched.practiceSettings && formikedit.errors.practiceSettings ? (
                      <p className="error_text text-danger">{formikedit.errors.practiceSettings}</p>
                    ) : null}
                  </div> */}
                    {/* <div class="form-group col-md-4 col-xs-12 col-sm-6">
                    <label>Provider Logo</label>

                    <input
                      type="file"
                      // #myInput
                      class="file"
                      name="practicelogo"
                      id="fileControl"
                      formControlName="practicelogo"
                      // (change)="dropped($event.target.files)"
                      accept="image/x-png,image/jpg,image/jpeg"
                      style={{width:'70px;',color:'transparent'}}
                       onChange={((e)=>setFiles(e.target.value))}
                       />
                      <span>{file?file.substring(12):<p className="error_text text-danger">* is required</p>}</span>

                  </div> */}
                    <div class="form-group col-md-4 col-xs-12 col-sm-6">
                      <div class="float-label">
                        <label htmlFor="layout_id">Layouts</label>
                        <select
                          class="adminInput"
                          formControlName="layout_id"
                          name="layout_id"
                          onChange={formikedit.handleChange}
                          onBlur={formikedit.handleBlur}
                          value={formikedit?.values?.layout_id}
                        >
                          {layoutsData?.map((lay, i) => (
                            <option key={i} value={lay?.layout_id}>
                              {lay?.layout_name}
                            </option>
                          ))}
                        </select>
                      </div>
                      {formikedit.touched.layout_id &&
                      formikedit.errors.layout_id ? (
                        <p className="error_text text-danger">
                          {formikedit.errors.layout_id}
                        </p>
                      ) : null}
                    </div>
                    <div class="form-group col-md-4 col-xs-12 col-sm-6">
                      <div class="float-label">
                        <label htmlFor="pricing_id">Pricing Model</label>
                        <select
                          class="adminInput"
                          formControlName="pricing_id"
                          name="pricing_id"
                          id="pricing_id"
                          onChange={formikedit.handleChange}
                          onBlur={formikedit.handleBlur}
                          value={formikedit?.values?.pricing_id}
                        >
                          <option value="">Select Pricing Model</option>
                          {pricingModel?.map((e, i) => (
                            <option key={i} value={e?.id}>
                              {e?.pricing_model}
                            </option>
                          ))}
                        </select>
                      </div>
                      {formikedit.touched.pricing_id &&
                      formikedit.errors.pricing_id ? (
                        <p className="error_text text-danger">
                          {formikedit.errors.pricing_id}
                        </p>
                      ) : null}
                    </div>
                    <div class="form-group col-md-4 col-xs-12 col-sm-6">
                      <div class="float-label">
                        <label htmlFor="version_id">
                          Pricing Model Version
                        </label>
                        <select
                          class="adminInput"
                          formControlName="version_id"
                          name="version_id"
                          id="version_id"
                          onChange={formikedit.handleChange}
                          onBlur={formikedit.handleBlur}
                          value={formikedit?.values?.version_id}
                        >
                          {version?.map((e) => {
                            return (
                              <option value={e?.id} key={e?.id}>
                                {e?.version}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      {formikedit.touched.version_id &&
                      formikedit.errors.version_id ? (
                        <p className="error_text text-danger">
                          {formikedit.errors.version_id}
                        </p>
                      ) : null}
                    </div>
                  </div>
                </form>
              </section>
            </Modal.Body>
          </div>
        </form>
        <Modal.Footer>
          <div>
            <button className="btn btn-secondary" onClick={handleCloseEdit}>
              Cancel
            </button>
          </div>
          <div>
            <button
              className="btn btn-primary"
              type="button"
              onClick={formikedit.handleSubmit}
            >
              Update
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      <div className="frameshadow">
        <ToolkitProvider
          bootstrap4
          keyField="id"
          data={loanData}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <SearchBar {...props.searchProps} />
              <ClearSearchButton {...props.searchProps} />
              <hr />
              <BootstrapTable
                bootstrap4
                key="loan_ref"
                // defaultSorted={defaultSorted}
                pagination={pagination}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    </div>
  );
}

export default PracticeManagement;
