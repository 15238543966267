export const initialState = {
  formData: {},
  errors: {},
  authData: {},
  formSubmitted: false,
  cosignerCheckbox: false,
  showToast: false,
  toastMessage: "",
  toastType: "",
  offers: [],
  loanId: "",
  globalStep: null,
  currentStep: null,
  signature: null,
  selectLoanPopup: false,
  emailPhonePopup: false,
  frameId: null,
  layoutId: null,
  socket: null,
  currency: '',
  isInviteOptionInAdmin:null
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "SET_VALUES":
      let data = action.payload;
      return {
        ...state,
        formData: { ...state.formData, ...data },
      };

    case "SET_AUTH":
      let authdata = action.payload;
      return {
        ...state,
        authData: { ...state.authData, ...authdata },
      };

    case "SET_ERRORS":
      let err = action.payload;
      return {
        ...state,
        errors: { ...state.errors, ...err },
      };

    case "REMOVE_ERROR":
      // console.log("REMOVE_ERROR --->", action.payload);
      delete state.errors[action.payload];
      return {
        ...state,
      };

    case "CLEAR_VALUES":
      return {
        ...state,
        formData: {},
      };

    case "FORM_SUBMIT":
      return {
        ...state,
        formSubmitted: action.payload,
      };

    case "SET_COSIGNER":
      return {
        ...state,
        cosignerCheckbox: action.payload,
      };
    case "TOAST":
      return {
        ...state,
        showToast: true,
        toastMessage: action.toastMessage,
        toastType: action.toastType,
      };

    case "LOAN_ID":
      return {
        ...state,
        loanId: action.payload,
      };

    case "SET_OFFERS":
      return {
        ...state,
        offers: action.payload,
      };
    case "SET_SIGN":
      return {
        ...state,
        signature: action.payload,
      };

    case "SET_STEP":
      return {
        ...state,
        globalStep: action.payload,
      };

    case "SET_CURRENT_STEP":
      return {
        ...state,
        currentStep: action.payload,
      };

    case "CLEAR_ERRORS":
      return {
        ...state,
        errors: {},
      };

    case "SET_LOANAMOUNT_POPUP":
      return {
        ...state,
        selectLoanPopup: action.payload,
      };

    case "SET_EMAILPHONE_POPUP":
      return {
        ...state,
        emailPhonePopup: action.payload,
      };

    case "SET_FRAMEID":
      return {
        ...state,
        frameId: action.payload,
      };

    case "SET_LAYOUTID":
      return {
        ...state,
        layoutId: action.payload,
      };

    case "SET_SOCKET":
      return {
        ...state,
        socket: action.payload,
      }
    case "SET_CURRENCY":
      return {
        ...state,
        currency: action.payload
      };
      case "SET_LATEST_WF_CONFIG":
        return {
          ...state,
          isInviteOptionInAdmin: action.payload
        };

    default:
      return state;
  }
};
