import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import { AuthGet } from "../../common_var/httpService";
import BootstrapTable from 'react-bootstrap-table-next';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import CustomModal from '../../Components/Modal/Modal';

const Comments = () => {
    const [userData, setUserData] = useState([]);
    const queryParams = useLocation()
    let params = queryParams.pathname.split('/');
    const loanId = params[params.length - 1];
    const loanStage = params[params.length - 2];
    const [page, setPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        getlist();
    }, [queryParams]);

    const getlist = async () => {
        console.log("params Id: ", loanId);
        console.log("stage: ", loanStage);

        await AuthGet(`opportunities/fetch-comments/${loanId}`, "admin")
            .then((res) => {
                if (res["statusCode"] == 200) {
                    for (let i = 0; i < res.data.length; i++) {
                        res.data[i].id = i;
                    }
                    setUserData(res.data);
                    setPage(Math.ceil(res.data.paymentScheduleDetails.length / 10));
                    setCurrentPage(1);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const columns = [
        {
            dataField: "", text: 'S.NO',
            formatter: (e, column, columnIndex, row, rowIndex) => {
                let total = currentPage * 5;
                total = total - 5;
                let slNo = total + columnIndex + 1;
                console.log("Total: ", total);
                return slNo;
            },
            headerStyle: {
                width: "100px"
            },
            style: {
                overflow: 'hidden'
              }
        },
        {
            dataField: "subject", text: 'Subject',
            headerStyle: {
                width: "150px"
            },
            style: {
                overflow: 'hidden'
              }
        },
        { dataField: "comments", text: 'Comments', style: {
            overflow: 'hidden'
          } },
        { dataField: "name", text: 'Created By', style: {
            overflow: 'hidden'
          } },
        { dataField: "createdat", text: 'Created Date', style: {
            overflow: 'hidden'
          } }
    ];

    const pagination = paginationFactory({
        page: page,
        sizePerPage: 10,
        lastPageText: '>>',
        firstPageText: '<<',
        nextPageText: '>',
        prePageText: '<',
        showTotal: true,
        alwaysShowAllBtns: true,
        onPageChange: function (page, sizePerPage) {
            console.log('page', page);
            console.log('sizePerPage', sizePerPage);
            setCurrentPage(page);
        },
        onSizePerPageChange: function (page, sizePerPage) {
            console.log('page', page);
            console.log('sizePerPage', sizePerPage);
        }
    });

    return (
        <>
            <div className='frameshadow'> 
                <div style={{marginBottom: '20px'}}>
                    <CustomModal btnName={"Add Any Query"} data={loanId} fun={getlist} />
                </div>
                {userData?.length > 0 ?
                <div>
                    <BootstrapTable
                        bootstrap4
                        keyField='id'
                        data={userData}
                        columns={columns}
                        pagination={pagination} />
                </div>
                :
                <div></div>
            } </div>


            {/* <div>
                <BootstrapTable 
                    bootstrap4 
                    keyField='id' 
                    data={userData} 
                    columns={columns}
                    pagination={pagination} />
            </div> */}
        </>
    )
}

export default Comments
