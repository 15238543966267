import React from "react";
import { useNavigate } from "react-router-dom";
import { Post } from "../../common_var/httpService";
import { useStateValue } from "../../Context/StateProvider";
import logo from "../../assets/images/brand_logo.svg";
import Brandlogo from "../../assets/images/brand_logo.svg";
import Toast from "../../Components/Toaster/Toast";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { useState, useEffect } from "react";
import {
  Container,
  Button,
  Grid,
  Paper,
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { io } from "socket.io-client";
const Login = ({ data: Values }) => {
  const navigate = useNavigate();
  let url = window.location.href;
  let splittedURL = url.split("//");
  console.log('splittedURL::: ', splittedURL);
  let isLocalhost = process.env.REACT_APP_ISLOCALENV == "true" ? true : false;
  const bank_verification_id = sessionStorage.getItem('bank_url');
  const verification_token = sessionStorage.getItem('token');

  const [login, setLogin] = useState(false);
  const [user_id, setUser_id] = useState();
  const [{ }, dispatch] = useStateValue();
  const [passwordType, setPasswordType] = useState("password");
  // const handlePasswordChange = (evnt) => {
  //   setPasswordInput(evnt.target.value);
  // }

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: {
        email: "",
        password: "",
      },
      validationSchema: Yup.object().shape({
        email: Yup.string()
          .matches(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g,
            "Please enter a valid email"
          )
          .required("Mail Address Is Required"),
        password: Yup.string()
          .required("Password Is Required")
          .max(16, "Password must be less than 16 characters long"),
      }),
      onSubmit: async (values) => {
        let data = {
          email: values.email.toLowerCase(),
          password: values.password,
        };

        await Post(`${Values.url}`, data, Values.key)
          .then((res) => {
            // debugger;
            //console.log("test", res);
            if (res["statusCode"] === 200) {
              console.log('res::: ', res);
              const auth = {
                token: res.jwtAccessToken,
                user: res.resuser,
              };

              if (bank_verification_id === res.loan_id) {
                sessionStorage.clear()
                if(isLocalhost){
                  window.open(`${process.env.REACT_APP_ONBOARDING_URL}/onboarding/bank-lookup/${bank_verification_id}/${verification_token}`, '_self')
                }
                else{
                  window.open(
                    `${splittedURL[0]}//app${res.layout_key}-${splittedURL[1].split(".")[0]
                    }.${process.env.REACT_APP_ONBOARDING_DOMAIN
                    }/onboarding/bank-lookup/${bank_verification_id}/${verification_token}`,
                    "_self"
                  )
                }
              }
              else {
                if (Values.key === "borrower") {
                  if (res["role_name"] == "customer") {
                    if (res["resuser"]["twofactorauth"] === "N") {
                      if (res.loanDetails[0]?.status_flag === "waiting") {
                        sessionStorage.setItem(
                          "borrower_token",
                          res.jwtAccessToken
                        );
                        sessionStorage.setItem("userId", res["resuser"]["id"]);
                        !isLocalhost
                          ? window.open(
                            `${splittedURL[0]}//app${res.layout_key}-${splittedURL[1].split(".")[0]
                            }.${process.env.REACT_APP_ONBOARDING_DOMAIN
                            }/onboarding/${res.loanDetails[0].id}/${res.jwtAccessToken
                            }`,
                            "_self"
                          )
                          : window.open(
                            `${process.env.REACT_APP_ONBOARDING_URL}/onboarding/${res.loanDetails[0].id}/${res.jwtAccessToken}`
                          );
                      } else if (res.loanDetails.length === 0) {
                        sessionStorage.setItem("userId", res?.resuser?.id);
                        sessionStorage.setItem(
                          "borrower_token",
                          res?.jwtAccessToken
                        );
                        navigate(`/selectprovider`);
                      } else {
                        let socket = io(process.env.REACT_APP_SOCKET_SERVER, {
                          reconnection: false,
                        });
                        dispatch({
                          type: "SET_SOCKET",
                          payload: socket,
                        });
                        socket?.emit("newUser", res["resuser"]["id"]);
                        sessionStorage.setItem("login", true);
                        sessionStorage.setItem("UserId", res["resuser"]["id"]);
                        sessionStorage.setItem(
                          "UD_firstName",
                          res["resuser"]["firstname"]
                        );
                        sessionStorage.setItem(
                          "UD_lastName",
                          res["resuser"]["lastname"]
                        );
                        sessionStorage.setItem(
                          "UD_email",
                          res["resuser"]["email"]
                        );
                        sessionStorage.setItem(
                          "borrower_token",
                          res["jwtAccessToken"]
                        );

                        if (
                          sessionStorage.getItem("acceptance_loan_id") ==
                          res.loanDetails[0].id
                        ) {
                          if (sessionStorage.getItem("acceptance_form")) {
                            sessionStorage.removeItem("acceptance_loan_id");
                            sessionStorage.removeItem("acceptance_form");
                            !isLocalhost
                              ? window.open(
                                `${splittedURL[0]}//app${res.layout_key}-${splittedURL[1].split(".")[0]
                                }.${process.env.REACT_APP_ONBOARDING_DOMAIN
                                }/submit-application/${res.loanDetails[0].id}/${res.jwtAccessToken
                                }`,
                                "_self"
                              )
                              : window.open(
                                `${process.env.REACT_APP_ONBOARDING_URL}/submit-application/${res.loanDetails[0].id}/${res["jwtAccessToken"]}`,
                                "_self"
                              );

                            let socket = io(process.env.REACT_APP_SOCKET_SERVER, {
                              reconnection: false,
                            });
                            dispatch({
                              type: "SET_SOCKET",
                              payload: socket,
                            });
                            socket?.emit("newUser", res["resuser"]["id"]);
                            sessionStorage.setItem("login", true);
                            sessionStorage.setItem("UserId", res["resuser"]["id"]);
                          }
                        } else {
                          navigate("/borrower/payment-schedule");
                        }
                      }
                    } else {
                      sessionStorage.setItem("UserId", res["resuser"]["id"]);
                      sessionStorage.setItem(
                        "UD_firstName",
                        res["resuser"]["firstname"]
                      );
                      sessionStorage.setItem(
                        "UD_lastName",
                        res["resuser"]["lastname"]
                      );
                      sessionStorage.setItem("UD_email", res["resuser"]["email"]);
                      navigate("/borrower/twofactorauth");
                    }
                  } else if (res["role_name"] == "co-borrower") {
                    // debugger;
                    if (res["resuser"]["twofactorauth"] === "N") {
                      if (
                        res["coSignor"][0]["coborrower_lastscreen"] !== 'onboarding Completed'
                      ) {
                        sessionStorage.setItem(
                          "borrower_token",
                          res.jwtAccessToken
                        );
                        sessionStorage.setItem("userId", res["resuser"]["id"]);
                        !isLocalhost
                          ? window.open(
                            `${splittedURL[0]}//app${res.layout_key}-${splittedURL[1].split(".")[0]
                            }.${process.env.REACT_APP_ONBOARDING_DOMAIN
                            }/cosignor/onboarding/${res["coSignor"][0].id}/${res.jwtAccessToken
                            }`,
                            "_self"
                          )
                          : window.open(
                            `${process.env.REACT_APP_ONBOARDING_URL}/cosignor/onboarding/${res["coSignor"][0].id}/${res.jwtAccessToken}`
                          );
                      } else if (
                        res["coSignor"][0]["coborrower_lastscreen"] === 'onboarding Completed'
                      ) {
                        // //else
                        sessionStorage.setItem("UserId", res["resuser"]["id"]);
                        let socket = io(process.env.REACT_APP_SOCKET_SERVER, {
                          reconnection: false,
                        });
                        dispatch({
                          type: "SET_SOCKET",
                          payload: socket,
                        });
                        socket?.emit("newUser", res["resuser"]["id"]);
                        sessionStorage.setItem("login", true);
                        sessionStorage.setItem(
                          "UD_firstName",
                          res["resuser"]["firstname"]
                        );
                        sessionStorage.setItem(
                          "UD_lastName",
                          res["resuser"]["lastname"]
                        );
                        sessionStorage.setItem(
                          "UD_email",
                          res["resuser"]["email"]
                        );
                        sessionStorage.setItem(
                          "borrower_token",
                          res["jwtAccessToken"]
                        );
                        navigate("/borrower/payment-schedule");
                      }
                    } else {
                      sessionStorage.setItem("UserId", res["resuser"]["id"]);
                      sessionStorage.setItem(
                        "UD_firstName",
                        res["resuser"]["firstname"]
                      );
                      sessionStorage.setItem(
                        "UD_lastName",
                        res["resuser"]["lastname"]
                      );
                      sessionStorage.setItem("UD_email", res["resuser"]["email"]);
                      navigate("/borrower/twofactorauth");
                    }
                  }
                  let url = sessionStorage.getItem("bank_form")
                  if (sessionStorage.getItem("bank_form")) {
                    navigate(url)
                  }
                } else if (Values.key === "admin") {
                  sessionStorage.setItem(
                    "resuser",
                    JSON.stringify(res["resuser"])
                  );
                  sessionStorage.setItem("pages", JSON.stringify(res["pages"]));
                  sessionStorage.setItem("tabs", JSON.stringify(res["tabs"]));
                  sessionStorage.setItem("UserId", res["resuser"]["id"]);
                  if (res["resuser"]["twofactorauth"] === "N") {
                    let socket = io(process.env.REACT_APP_SOCKET_SERVER, {
                      reconnection: false,
                    });
                    dispatch({
                      type: "SET_SOCKET",
                      payload: socket,
                    });
                    socket?.emit("newUser", res["resuser"]["id"]);
                    sessionStorage.setItem("login", true);
                    sessionStorage.setItem("admin_token", res["jwtAccessToken"]);
                    // gopage(res["pages"][0], res["tabs"]);
                    navigate("/admin/dashboard");
                  } else {
                    navigate("/admin/twofactorauth");
                  }
                }

              }


              // dispatch({
              //   type: "SET_AUTH",
              //   payload: auth,
              // });
            } else {
              toast.error(res["message"]);
              // dispatch({
              //   type: "TOAST",
              //   toastMessage: res["message"],
              //   toastType: "error",
              // });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
    });

  // useEffect(() => {
  //   alert('working emit')
  //   console.log('login', login)
  //   if (login) {

  //   }

  // }, [login]);

  const gopage = (list, tabs) => {
    switch (list.name) {
      case "Dashboard":
        navigate("/admin/dashboard");
        break;
      case "Approved Application":
        navigate("/admin/approved");
        break;
      case "Pending Application":
        navigate("/admin/pendings");
        break;
      case "Incomplete Application":
        navigate("/admin/incomplete");
        break;
      case "Denied Application":
        navigate("/admin/dashboard");
        break;
      case "Funded Contracts":
        navigate("/admin/funded-contracts");
        break;
      case "Settings":
        gosetting(tabs[list.id]);
        break;
      case "Installer Management":
        navigate("/admin/installer");
        break;
      case "Users":
        navigate("/admin/users");
        break;
      case "Start Application":
        navigate("/admin/start-application");
        break;
      case "Funding Contracts":
        navigate("/admin/funding-contracts");
        break;
      default:
        sessionStorage.clear();
        break;
    }
  };

  const gosetting = (list) => {
    switch (list.name) {
      case "Audit Log":
        navigate("/admin/settings/auditlog");
        break;
      case "Questions":
        navigate("/admin/settings/questions");
        break;
      case "Admin Security":
        navigate("/admin/settings/admin-security");
        break;
      case "Roles":
        navigate("/admin/settings/roles");
        break;
      case "DecisionEngine":
        navigate("/admin/settings/decisionengine");
        break;
      default:
        sessionStorage.clear();
        break;
    }
  };

  return (
    <>
      <Toast />
      <section className="logincomponent">
        <div className=" ">
          <div className="loginSection">
            <div className="login-form">
              <div className="main-div">
                <div className="panel">
                  <div className="brand_logo">
                    <img
                      src={Brandlogo}
                      alt=""
                      style={{ marginBottom: "40px" }}
                    />
                  </div>
                </div>
                {/* <form autoComplete="off" onSubmit={handleSubmit}>
                  <div className="form-group">
                    <input
                      value={values.email}
                      onChange={handleChange}
                      id="email"
                      type='email'
                      className="form-control"
                      placeholder="Email Address"
                      onBlur={handleBlur}
                    />
                    <br />
                    {errors.email && touched.email && <p style={{ color: 'red' }}>{errors.email}</p>}
                  </div>

                  <div className="form-group">
                    <input
                      value={values.password}
                      onChange={handleChange}
                      id="password"
                      type={passwordType === "password" ? "password" : "text"}
                      className="form-control"
                      placeholder="Password"
                      onBlur={handleBlur}
                    />
                    {passwordType === "password" ? <i className="bi bi-eye-slash" onClick={togglePassword}></i> : <i className="bi bi-eye" onClick={togglePassword}></i>}
                    <br />
                    {errors.password && touched.password && <p style={{ color: 'red' }}>{errors.password}</p>}

                  </div>

                  <div className="forgot">
                    <span className="cursorpointer" onClick={() => {
                      navigate(`/${Values.key}/forgot-password`)
                    }}>Forgot password?</span>
                  </div>
                  <button type="submit" className="button">
                    Login
                  </button>
                </form> */}

                <form onSubmit={handleSubmit}>
                  <div style={{ marginBottom: "20px" }}>
                    <TextField
                      name="email"
                      type="text"
                      fullWidth
                      // label="Enter your email"
                      placeholder="Email Address"
                      variant="outlined"
                      value={values.email}
                      onChange={(e) =>{
                        if(/^[ ]/g.test(e.target.value)){
                          e.preventDefault()
                        }else{
                          setFieldValue("email", e.target.value)
                        }
                       }}
                      id="email"
                      // className="form-control"
                      onBlur={handleBlur}
                    />
                    {errors.email && touched.email && (
                      <p style={{ color: "red" }}>{errors.email}</p>
                    )}
                  </div>

                  <div style={{ marginBottom: "20px" }}>
                    <TextField
                      value={values.password}
                      onChange={handleChange}
                      id="password"
                      fullWidth
                      type={passwordType === "password" ? "password" : "text"}
                      // className="form-control"
                      placeholder="Password"
                      onBlur={handleBlur}
                      // label="Enter your password"
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              // onClick={handlePassVisibilty}
                              aria-label="toggle password"
                              edge="end"
                              onClick={togglePassword}
                            >
                              {passwordType === "password" ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {errors.password && touched.password && (
                      <p style={{ color: "red" }}>{errors.password}</p>
                    )}
                  </div>

                  <div className="forgot">
                    <span
                      className="cursorpointer"
                      onClick={() => {
                        navigate(`/${Values.key}/forgot-password`);
                      }}
                    >
                      Forgot password?
                    </span>
                  </div>
                  <button type="submit" className="button">
                    Login
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
